import React, { useContext } from 'react'
import SaveSurgeonForm from './forms/SaveSurgeonForm';
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from '../components/Flyout';
import ObjectWrapper from "../components/ObjectWrapper"
import { Notifications } from './NotificationsComponent';
import { ValidResult } from '../utils/Utils';

function _SaveSurgeonDialogComponent(props) {
    const toastr = useContext(Notifications)

    props.toastr.setObject(toastr)

    return (null)
}

class SaveSurgeonDialogComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            surgeon: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                address: '',
                tags: [],
                hospitals: []
            },
            showEditSurgeonModal: props.showEditSurgeonModal
        };
        
        this.toastr = ObjectWrapper()
    };

    onSaveCallback = (result) => {
        if (ValidResult(result)) {
            this.toastr.ShowSuccess("Successfully saved the surgeon")
            this.props.handleClose();
        }
        else {
            this.toastr.ShowError("Failed to save the surgeon")
        }
    }

    componentWillMount() {
        this.setState({ showEditSurgeonModal: false })
    }

    componentWillReceiveProps({ surgeon, showEditSurgeonModal }) {
        if (!surgeon) {
            surgeon = this.state.surgeon
        }
        this.setState({
            surgeon,
            showEditSurgeonModal
        });
    }

    render() {
        var state = this.state;
        var props = this.props;
        return (
            <>
                <_SaveSurgeonDialogComponent toastr={this.toastr} />
                <Flyout onClose={() => { this.props.handleClose() }} isVisible={state.showEditSurgeonModal} id="edit-hospital-flout" showCloseButton={true} size="small">
                    <FlyoutHead><h2 className="title-flyout"> Save Surgeon</h2></FlyoutHead>
                    <FlyoutBody>
                        <SaveSurgeonForm
                            surgeon={state.surgeon}
                            onSaveCallback={this.onSaveCallback}
                            onError={this.onError}
                        /></FlyoutBody>
                    <FlyoutFooter> </FlyoutFooter>
                </Flyout>
            </>
        )
    }
}

export default SaveSurgeonDialogComponent