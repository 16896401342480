import React, { Component } from 'react';
import SurgeonTable from './SurgeonTable';
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import MultiselectTagsFilterEntry from '../filterAndPagination/components/MultiselectTagsFilterEntry';
import FilterHandler from '../filterAndPagination/FilterHandler';
import { MoreAction, MoreActionsMenu, MoreActionsItem } from '../MoreActions';
import "../../css/base/basicTable.css";


class SurgeonTableWithFilter extends Component {

    render() {
        return (
            <>
                <FilterHandler context={this.props.context}>
                    <TextFilterEntry name="Name" />
                    <TextFilterEntry name="Email" />
                    <TextFilterEntry name="Phone" />
                    <MultiselectTagsFilterEntry name="Tags"
                        placeholder="Select Tag"
                        url="Tag/GetAll" />
                </FilterHandler>

                <SurgeonTable
                    {...this.props}
                    hiddenData={{
                        HospitalId: this.props.hospitalId
                    }}
                />
            </>
        );
    }
}

export default SurgeonTableWithFilter;