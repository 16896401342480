export default function ObjectWrapper(obj = null) {
    const props = []
    const out = () => { return obj }

    const collectProps = () => {
        Object.keys(obj).forEach((key) => {
            out[key] = obj[key]

            props.push(key)
        })
    }

    const clearProps = () => {
        props.forEach((prop) => {
            try {
                delete out[prop]
            }
            catch (ex) { }
        })
    }

    const setObject = (_obj) => {
        obj = _obj

        clearProps()

        if (!obj) {
            props.length = 0
            return
        }
        collectProps()
    }

    const safeCall = (fnName, ...args) => {
        if (obj && typeof (obj[fnName]) === "function") {
            return obj[fnName](...args)
        }

        return null
    }

    out.setObject = setObject
    out.safeCall = safeCall

    return out
}