import React from 'react';
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import GalleryVideoComponent from '../GalleryVideoComponent';
import { GalleryItemType } from '../../utils/Utils';
import { GalleryGridContext } from "../contexts"

function VideoTab(props) {

    const renderGalleryVideos = (items, id, activeIndex) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }

        return (
            <div className="d-gallery-container">
                {
                    items.map(item =>
                        <GalleryVideoComponent
                            galleryGrid={props.galleryGrid}
                            key={item.Id}
                            source={item.Url}
                            id={item.Id}
                            class="gallery-item"
                            handleClick={props.handleClick}
                            name={item.Name}
                            deleteResource={props.deleteResource}
                            editItem={props.editItem}
                        />
                    )
                }
            </div>
        )
    }

    return (
        <>
            <PaginationHandler
                context={GalleryGridContext}
                id="inactive-gallery-pagination"
                url="/Gallery/GetGalleryPage"
                activeIndex={GalleryItemType.Video}
                processor={renderGalleryVideos} />
        </>
    )

}

export default VideoTab;