import React, { Component } from 'react'

import { SiteContext } from '../components/contexts';

import TranslationSwitch from '../components/TranslationSwitch';

import "../css/header.css"

import ThemeSwitch from '../components/ThemeSwitch';
import SiteState from '../components/SiteState';

class Header extends Component {
    LogOut = (event) => {
        this.props.LogOutAction({ reasone: "None" })
    }

    render() {
        let isAuth = this.props.isAuthenticated

        return (
            <div className="site-header">
                <SiteContext.Consumer>
                    {
                        ({ state, translate, LogOut }) => (
                            <>
                                {/* <TranslationSwitch /> */}
                                {/* <ThemeSwitch /> */}
                                {
                                    state.siteState = SiteState.LoggedIn ?
                                        (
                                            <>
                                                <div className="logout-panel">
                                                    <label onClick={() => {
                                                        LogOut()
                                                    }}>Log out</label>
                                                </div>
                                                <div className="user-panel">
                                                    <label>
                                                        {
                                                            `Welcome ${state.userData.email}|${state.userData.name}`
                                                        }
                                                    </label>
                                                </div>
                                            </>
                                        ) :
                                        (
                                            <div>
                                                <label>Not authed</label>
                                            </div>
                                        )
                                }
                            </>
                        )
                    }
                </SiteContext.Consumer>
            </div >
        )
    }
}

export default Header
