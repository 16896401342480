import React, { Component } from 'react';
import FormEntry from '../../form/FormEntry';
import { Form } from '../../form/Form';
import InputComponent from '../../components/InputComponent';
import EntitiesSelect2Component from '../EntitySelect2Component';
import { ExtractIds, ValidResult } from '../../utils/Utils';
import SafeSimpleEvent from '../SafeSimpleEvent';
import DialogManager from "../DialogManager"

class SaveHospitalForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hospital: props.hospital,
            formEvents: SafeSimpleEvent()
        }
    }

    onSaveCallback = (data) => {
        this.setState({
            hospital: {
                name: data.Data.Name,
                phone: data.Data.Phone,
                email: data.Data.Email,
                address: data.Data.Address,
                tags: ExtractIds(data.Data.Tags)
            }
        })
        this.props.onSaveCallback(data);
    }

    componentWillReceiveProps = (props) => {
        this.setState({ hospital: props.hospital })
    }

    onTagModalEvent = ({ type, payload }) => {
        if (type === "save_tag_success") {
            this.state.formEvents.riseEvent({
                type: "TagIds_add_option",
                payload: {
                    key: payload.Id,
                    text: payload.Name,
                    value: payload.Id
                }
            })
        }
    }

    render() {
        var props = this.props;
        var hospital = this.state.hospital;
        return (
            <Form name="HospitalUIModel" url="Hospital/Save" 
                containsFile={false}
                externalEvents={this.state.formEvents}
                callback={this.onSaveCallback} 
                onError={props.onError} 
                dontClear={true}
                onSubmitStart={this.props.onSubmitStart}
                align={props.align ? props.align : "inline"}
                hiddenData={{ Id: props.hospital ? props.hospital.id : null }}>
                <FormEntry name="Name" text="Name*" value={hospital ? hospital.name : ''} >
                    <InputComponent />
                </FormEntry>
                <FormEntry name="Phone" text="Phone*" value={hospital ? hospital.phone : ''} >
                    <InputComponent />
                </FormEntry>
                <FormEntry name="Email" text="Email*" value={hospital ? hospital.email : ''} >
                    <InputComponent />
                </FormEntry>
                <FormEntry name="Address" text="Address*" value={hospital ? hospital.address : ''} >
                    <InputComponent />
                </FormEntry>
                <FormEntry
                    name="TagIds"
                    action={(
                        <span onClick={
                            () => {
                                DialogManager.Show(
                                    "tag_dialog",
                                    this.onTagModalEvent)
                            }
                        }>+Create Tag</span>
                    )}
                    text="Tags*" value={hospital ? [...hospital.tags] : []} >
                    <EntitiesSelect2Component
                        placeholder="Select Tags"
                        url="Tag/GetAll"
                    />
                </FormEntry>
            </Form>
        )
    }
}

export default SaveHospitalForm