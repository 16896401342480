import React from 'react'
import SurgeonTable from './table/SurgeonTable';
import SaveHospitalForm from './forms/SaveHospitalForm'
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from '../components/Flyout';
import AxiosHelper from '../utils/AxiosHelper';
import { ValidResult } from '../utils/Utils';
import SafeSimpleEvent from './SafeSimpleEvent';

class SaveHospitalDialogComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hospital: {
                id: "",
                name: "",
                phone: "",
                address: "",
                email: "",
                tags: []
            },
            screenIsReady: true,
            showDialog: false,

            internalEvents: SafeSimpleEvent()
        }

        if (props.eventsProxy) {
            props.eventsProxy.setEvent(this.state.internalEvents)
        }

        this.state.internalEvents.subscribe(this.handleEvents, "SAVE_HOSPITAL_DIALOG")
    };

    refreshData = (hospitalId) => {
        AxiosHelper.get(`Hospital/GetHospital?id=${hospitalId}`)
            .then(response => {

                let tags = response.data.Tags.map(tag => {
                    return tag.Id;
                });

                this.setState({
                    hospital: {
                        id: hospitalId,
                        name: response.data.Name,
                        phone: response.data.Phone,
                        email: response.data.Email,
                        address: response.data.Address,
                        tags: tags,
                    },
                    showDialog: true
                })

            }).catch(function (err) {
                console.log(err);
            });
    }

    handleEvents = ({ type, payload }) => {
        switch (type) {

            case "show_edit_hospital_dialog":
                this.refreshData(payload)
                break;

            case "close_edit_hospital_dialog":
                this.setState({ showDialog: false })

                this.props.externalEvents.riseEvent("filter_hospitals")
                break;
        }
    }

    onSaveCallback = (result) => {
        if (ValidResult(result)) {
            this.props.toastr.ShowSuccess("Successfully updated the hospital")
        }
        else {
            this.props.toastr.ShowError("Failed to update the hospital")
        }
    }

    onError = (result) => {
        this.props.toastr.ShowError("An error occurred, please try again")
    }

    onCloseDialog = () => {
        this.props.onClose && this.props.onClose();
        this.setState({ showDialog: false })

        this.state.internalEvents.riseEvent({ type: "filter_hospitals" })
    }

    render() {
        var props = this.props;
        return this.state.screenIsReady ? (
            <Flyout isVisible={this.state.showDialog} id="edit-hospital-flyout" showCloseButton={true} onClose={this.onCloseDialog} size="full">
                <FlyoutHead><h2 className="title-flyout">Edit Hospital</h2></FlyoutHead>
                <FlyoutBody>

                    <SaveHospitalForm
                        hospital={this.state.hospital}
                        onError={this.onError}
                        onSaveCallback={this.onSaveCallback} />

                    <SurgeonTable
                        externalProxy={this.props.externalProxy}
                        saveHospitalProxy={this.state.internalEvents}
                        editSurgeon={props.editSurgeon}
                        hiddenData={{
                            HospitalId: this.state.hospital.id
                        }} />

                </FlyoutBody>
                <FlyoutFooter></FlyoutFooter>
            </Flyout>
        ) : null
    }
}

export default SaveHospitalDialogComponent