import React, { Component } from 'react';
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import FilterHandler from '../filterAndPagination/FilterHandler';
import { MoreActionsMenu, MoreActionsItem } from '../MoreActions';
import moment from "moment"
import MultiselectFilterEntry from '../filterAndPagination/components/MultiselectTagsFilterEntry';
import { Confirm } from "../ConfirmModule"
import AxiosHelper from '../../utils/AxiosHelper';
import { ValidResult } from '../../utils/Utils';
import { PageStateHandler } from '../PageState';

const InformationCardTableContext = React.createContext();

class InformationCardTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            contextValue: FilterHandler.BuildContextValue(),
        }

        if (props.saveQuestionnaireInfoCardProxy) {
            props.saveQuestionnaireInfoCardProxy.subscribe(this.handleEvent, "INFO_TABLE")
        }

        if (props.savePathwayProxy) {
            props.savePathwayProxy.subscribe(this.handleEvent, "INFO_TABLE")
        }

        if (props.saveInfoCardProxy) {
            props.saveInfoCardProxy.subscribe(this.handleEvent, "INFO_TABLE")
        }
    }

    handleEvent = ({ type, payload }) => {
        if (type === "filter") {
            this.state.contextValue.filterEvent.riseEvent()
        }
    }

    moreActionsItemClick = (menuItemId, obj) => {
        obj.isReadonly = this.props.isReadonly;
        if (menuItemId === "edit-info-card") {
            obj.isReadonly = obj.IsTemplate; //@TOOD change the condition

            if (obj.HasQuestionnaire) {
                this.props.saveQuestionnaireInfoCardProxy.riseEvent({ type: "show_edit_dialog", payload: obj })
            }
            else {
                this.props.saveInfoCardProxy.riseEvent({ type: "show_edit_dialog", payload: obj })
            }
        }
        else if (menuItemId === "delete-info-card") {
            Confirm.Show(
                `Confirm`,
                `Are you sure you want to delete this info card?"`,
                this.handleConfirm,
                { subType: "delete", obj }
            )
        }
    }

    handleConfirm = ({ type, payload }) => {
        const { subType, obj } = payload

        if (type === "ok") {
            if (subType === "delete") {
                this.deleteInfoCard(obj)
            }
        }
    }

    deleteInfoCard = (obj) => {
        PageStateHandler.SetLoading()

        AxiosHelper
            .post(`/Pathway/DeleteInfoCard?id=${obj.Id}`)
            .then((result) => {
                if (ValidResult(result.data)) {
                    this.props.toastr.ShowSuccess(`Successfully deleted info card for day "${obj.Day}"`)

                    this.state.contextValue.filterEvent.riseEvent()
                }
                else {
                    this.props.toastr.ShowError(`Failed to delete info card for day "${obj.Day}"`)
                }

                PageStateHandler.SetReady()
            })
            .catch((err) => {
                this.props.toastr.ShowError("An error occurred, please try again")
                PageStateHandler.SetReady()
            })
    }

    renderInformationCardTable = (items) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }

        return (
            <table className="d-table info-cards-table" data-more-actions>
                <thead>
                    <tr>
                        <th></th>
                        <th>Day</th>
                        <th>Time</th>
                        <th>Title</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(infoCard => {
                        return (
                            <tr key={infoCard.Id} data-isquestionnaire={infoCard.HasQuestionnaire ? "True" : "False"}>
                                <td width="4px">
                                </td>
                                <td>{infoCard.Day}</td>
                                <td>{moment(infoCard.Time + "Z").format("HH:mm")}</td>
                                <td>{infoCard.Info}</td>
                                <td>
                                    <MoreActionsMenu callback={this.moreActionsItemClick}>
                                        <MoreActionsItem text="Edit" id="edit-info-card" key="edit-info-card" obj={infoCard} />
                                        <MoreActionsItem text="Delete" id="delete-info-card" key="delete-info-card" obj={infoCard} />
                                    </MoreActionsMenu>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <>
                <InformationCardTableContext.Provider value={this.state.contextValue}>
                    <FilterHandler context={InformationCardTableContext}>
                        <TextFilterEntry name="Term" friendlyName="Search" />
                    </FilterHandler>
                    <div className="info-cards-legend">
                        <div>
                            <div></div>
                            <label>Info Cards</label>
                        </div>
                        <div>
                            <div></div>
                            <label>Questionnaire Cards</label>
                        </div>
                    </div>
                    <PaginationHandler
                        context={InformationCardTableContext}
                        id="pathways-info-card-pagination"
                        url={`/Pathway/GetPathwayInfoCardsPage?PathwayId=${this.props.pathwayId}`}
                        processor={this.renderInformationCardTable} />
                </InformationCardTableContext.Provider>
            </>
        );
    }
}

export default InformationCardTable;