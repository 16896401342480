import React, { Component } from "react";
import PracticeTable from "./PracticeTable";
import TextFilterEntry from "../filterAndPagination/components/TextFilterEntry";
import MultiselectTagsFilterEntry from "../filterAndPagination/components/MultiselectTagsFilterEntry";
import FilterHandler from "../filterAndPagination/FilterHandler";
import "../../css/base/basicTable.css";
import SafeSimpleEvent from "../SafeSimpleEvent";

class PracticeTableWithFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      internalEvents: SafeSimpleEvent(),
    };
  }
  render() {
    return (
      <>
        <FilterHandler
          context={this.props.context}
          externalEvent={this.state.internalEvents}
        >
          <TextFilterEntry name="Name" />
        </FilterHandler>

        <PracticeTable {...this.props} hiddenData={{}} />
      </>
    );
  }
}

export default PracticeTableWithFilter;
