import React, { useRef } from 'react';
import { GalleryItemType } from '../utils/Utils'

function GalleryVideoComponent(props) {
    const ref = useRef(null)

    const onClick = () => {
        props.editItem(props.id, props.name, props.source, GalleryItemType.Video)
    }
   
    return (
        <div className="d-gallery-video-entry">
            <video ref={ref} onClick={props.handleClick} alt={props.name} className={props.class} id={props.id} controls>
                <source src={props.source} type="video/mp4" />
            </video>
            <div className="d-galley-item-container">
                <span onClick={() => {
                    ref && ref.current.click()
                }} className="gallery-item-title">{props.name}</span>
                {props.deleteResource ?
                    <span onClick={props.deleteResource} class='delete-gallery-item' id={props.id}> </span>
                    : <></>
                }
                {
                    props.galleryGrid ?
                        <span onClick={onClick} class='edit-gallery-item' id={props.id} > </span>
                        : <></>
                }
            </div>
        </div>
    )
}

export default GalleryVideoComponent;