import React, { Component } from "react";
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from "./Flyout";
import { ValidResult } from "../utils/Utils";
import SavePracticeForm from "./forms/SavePracticeForm";
import SafeSimpleEvent from "./SafeSimpleEvent";

export class CreatePracticeDialogComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      internalEvents: SafeSimpleEvent(),
      isVisible: false,
    };

    if (this.props.externalEvents) {
      this.props.externalEvents.subscribe(
        this.handleEvents,
        "CREATE_PRACTICE_COMPONENT"
      );
    }
  }

  handleEvents = ({ type, payload }) => {
    if (type === "create_practice_show_dialog") {
      this.setState({ isVisible: true });
    } else if (type === "close_dialog") {
      this.setState({ isVisible: false });
    }
  };

  onCreteCallback = (result) => {
    if (ValidResult(result)) {
      this.props.toastr.ShowSuccess("Successfully created the practice");

      //we only filter if we successfully created the item
      this.props.externalEvents.riseEvent({ type: "filter_practice" });

      this.riseCloseEvent();
    } else {
      if (result.StatusCode > 0 && result.StatusCode == 86) {
        this.props.toastr.ShowError("User already exists with same email");
      } else {
        this.props.toastr.ShowError("Failed to create the practice");
      }
    }
  };

  onError = (err) => {
    this.props.toastr.ShowError("An error occurred, please try again");
  };

  riseCloseEvent = () => {
    this.props.externalEvents.riseEvent({ type: "close_dialog" });
  };

  render() {
    return (
      <Flyout
        onClose={() => {
          this.riseCloseEvent();
        }}
        isVisible={this.state.isVisible}
        id="edit-practice-flout"
        showCloseButton={true}
        size="small"
      >
        <FlyoutHead>
          <h2 className="title-flyout">Create Practice</h2>
        </FlyoutHead>
        <FlyoutBody>
          <SavePracticeForm
            align="1-col"
            onSaveCallback={this.onCreteCallback}
            onError={this.onError}
          />
        </FlyoutBody>
        <FlyoutFooter></FlyoutFooter>
      </Flyout>
    );
  }
}

export default CreatePracticeDialogComponent;
