import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SiteContext } from "./components/contexts";
import HospitalPage from "./pages/HospitalPage";
import PathwaysPage from "./pages/PathwaysPage";
import SurgeonsPage from "./pages/SurgeonsPage";
import QuestionnaireDetails from "./pages/QuestionnaireDetails";
import QuestionnairesAnswerPage from "./pages/QuestionnaireAnswerPage";
import PathwayDetails from "./pages/PathwayDetails";
import QuestionnairesPage from "./pages/QuestionnairesPage";
import GalleryPage from "./pages/GalleryPage";
import PageNotFound from "./pages/PageNotFound";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import MenuItem from "./components/menu/MenuItem";
import Menu from "./components/menu/Menu";
import PatientsPage from "./pages/PatientsPage";
import TagsPage from "./pages/TagsPage";
import PracticePage from "./pages/PracticePage";

const _Root = ({ translate }, props) => (
  <>
    <Header />
    <div className="site-container">
      <Router>
        <div className="site-body">
          <div className="site-left-side">
            <Menu>
              <MenuItem
                Id="logo-menu-item"
                text="SuXeed"
                to="/"
                isLogo={true}
              />
              <MenuItem Id="home-menu-item" text="Hospitals" to="/" />
              <MenuItem
                Id="practices-menu-item"
                text="Practices"
                to="/practices"
              />
              <MenuItem Id="surgeon-menu-item" text="Surgeons" to="/surgeons" />
              <MenuItem
                Id="pathways-menu-item"
                text="Pathways"
                to="/pathways"
                includedLocaitons={["pathwaydetails"]}
              />
              <MenuItem
                Id="questionnaires-menu-item"
                text="Questionnaires"
                to="/questionnaires"
                includedLocaitons={["savequestionnaire"]}
              />
              <MenuItem Id="gallery-menu-item" text="Gallery" to="/gallery" />
              <MenuItem Id="patient-menu-item" text="Patients" to="/patients" />
              <MenuItem
                Id="questionnaires-answers-menu-item"
                text="Answers"
                to="/questionnaire-answers"
              />
              <MenuItem Id="tags-menu-item" text="Tags" to="/tags" />
            </Menu>
          </div>
          <div className="site-right-side">
            <div className="d-site-wrapper">
              <Switch>
                <Route path="/" exact component={HospitalPage} />
                <Route path="/practices" exact component={PracticePage} />
                <Route path="/surgeons" exact component={SurgeonsPage} />
                <Route path="/pathways" exact component={PathwaysPage} />
                <Route
                  path="/pathways/pathwaydetails"
                  component={PathwayDetails}
                />
                <Route
                  path="/questionnaires/savequestionnaire"
                  component={QuestionnaireDetails}
                />
                <Route
                  path="/questionnaires"
                  exact
                  component={QuestionnairesPage}
                />
                <Route path="/gallery" exact component={GalleryPage} />
                <Route path="/patients" exact component={PatientsPage} />
                <Route
                  path="/questionnaire-answers"
                  exact
                  component={QuestionnairesAnswerPage}
                />
                <Route path="/tags" exact component={TagsPage} />
                <Route component={PageNotFound} />
              </Switch>
            </div>
          </div>
        </div>
      </Router>
      <Footer />
    </div>
  </>
);

class Root extends Component {
  render() {
    return (
      <>
        <SiteContext.Consumer>{_Root}</SiteContext.Consumer>
      </>
    );
  }
}

export default Root;
