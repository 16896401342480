import React, { Component, useContext } from 'react';

import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import FilterHandler from '../filterAndPagination/FilterHandler';
import { MoreActionsMenu, MoreActionsItem, MoreActionsLinkItem } from '../MoreActions';
import ListingTagsView from '../ListingTagsView';
import MultiselectFilterEntry from '../filterAndPagination/components/MultiselectTagsFilterEntry';
import AxiosHelper from '../../utils/AxiosHelper';
import { ValidResult } from '../../utils/Utils';
import { PageStateHandler } from '../PageState';
import { Confirm } from "../ConfirmModule"
import ObjectWrapper from '../ObjectWrapper';
import { withRouter } from "react-router";

import "../../css/tableWithLegend.css"


const PathwayTableContext = React.createContext(FilterHandler.BuildContextValue());
function _PathwayTable(props) {
    const tableContext = useContext(PathwayTableContext)

    props.tableContext.setObject(tableContext)

    return null
}
class PathwayTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tableContext: ObjectWrapper()
        }
    }

    onMenuItemClick = (menuItemId, obj) => {
        if (menuItemId === "duplicate") {
            Confirm.Show(
                `Confirm`,
                `Are you sure you want to duplicate pathway "${obj.Name}"?`,
                this.confirmHandler,
                { subType: "duplicate", obj }
            )
        }
        else if (menuItemId === "delete") {
            Confirm.Show(
                `Confirm`,
                `Are you sure you want to delete pathway "${obj.Name}"?`,
                this.confirmHandler,
                { subType: "delete", obj }
            )
        }
    }

    confirmHandler = ({ type, payload }) => {
        const { subType, obj } = payload

        if (type === "ok") {
            if (subType === "duplicate") {
                this.duplicatePathway(obj.Id, obj.Name)
            }
            else if (subType === "delete") {
                this.deletePathawy(obj.Id, obj.Name)
            }
        }
    }

    duplicatePathway = (id, name) => {
        PageStateHandler.SetLoading()

        AxiosHelper
            .post(`/Pathway/Duplicate?id=${id}`)
            .then((result) => {
                if (ValidResult(result.data)) {
                    this.props.toastr.ShowSuccess(`Successfully duplicated pathawy "${name}"`)

                    const { Value } = result.data.Data

                    this.props.history.push(`/pathways/pathwaydetails?pathwayId=${Value}`)
                }
                else {
                    this.props.toastr.ShowError(`Failed to duplicate pathawy "${name}"`)
                }

                PageStateHandler.SetReady()
            })
            .catch((err) => {
                this.props.toastr.ShowError("An error occurred, please try again")
                PageStateHandler.SetReady()
            })
    }

    deletePathawy = (id, name) => {
        PageStateHandler.SetLoading()

        AxiosHelper
            .post(`/Pathway/Delete?id=${id}`)
            .then((result) => {
                if (ValidResult(result.data)) {
                    this.props.toastr.ShowSuccess(`Successfully deleted pathawy "${name}"`)

                    this.state.tableContext.filterEvent.riseEvent();
                }
                else {
                    this.props.toastr.ShowError(`Failed to delete pathawy "${name}"`)
                }

                PageStateHandler.SetReady()
            })
            .catch((err) => {
                this.props.toastr.ShowError("An error occurred, please try again")
                PageStateHandler.SetReady()
            })
    }

    renderPathwaysTable = (items) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }

        return (
            <table className="d-table table-with-legend" data-more-actions>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Tags</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map(pathway => {
                            return (
                                <tr key={pathway.Id} data-issystem={pathway.IsTemplate ? "True" : "False"}>
                                    <td width="4px">
                                    </td>
                                    <td onClick={() => {
                                        this.props.history.push(`/pathways/pathwaydetails?pathwayId=${pathway.Id}&isTemplate=${pathway.IsTemplate}`);
                                    }}>{pathway.Name}</td>
                                    <td>
                                        <ListingTagsView tags={pathway.Tags} />
                                    </td>
                                    <td>
                                        <MoreActionsMenu callback={this.onMenuItemClick}>
                                            <MoreActionsLinkItem to={`/pathways/pathwaydetails?pathwayId=${pathway.Id}&isTemplate=${pathway.IsTemplate}`} text="Edit" id="edit" key={`${pathway.Id}-edit`} obj={pathway} />
                                            <MoreActionsItem text="Delete" id="delete" key={`${pathway.Id}-delete`} obj={pathway} />
                                            <MoreActionsItem text="Duplicate" id="duplicate" key={`${pathway.Id}-duplicate`} obj={pathway} />
                                        </MoreActionsMenu>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <>
                <_PathwayTable tableContext={this.state.tableContext} />
                <FilterHandler context={PathwayTableContext}>
                    <TextFilterEntry name="Name" friendlyName="Name" />
                    <MultiselectFilterEntry
                        name="Tags"
                        placeholder="Select Tag"
                        url="Tag/GetAll"
                    />
                </FilterHandler>
                <div className="template-legend">
                    <div>
                        <div></div>
                        <label>Created by user</label>
                    </div>
                    <div>
                        <div></div>
                        <label>System</label>
                    </div>
                </div>
                <PaginationHandler
                    takeCount={10}
                    context={PathwayTableContext}
                    id="pathways-pagination"
                    url="/Pathway/GetPathwaysPage"
                    processor={this.renderPathwaysTable} />
            </>
        );
    }
}

export default withRouter(PathwayTable);