import React, { Component } from 'react'
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from '../components/Flyout';
import SaveHospitalForm from '../components/forms/SaveHospitalForm';
import SafeSimpleEvent from './SafeSimpleEvent';
import { ValidResult } from '../utils/Utils';

export class CreateHospitalComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            internalEvents: SafeSimpleEvent(),
            isVisible: false
        }

        if (props.eventsProxy) {
            props.eventsProxy.setEvent(this.state.internalEvents)
        }

        this.state.internalEvents.subscribe(this.handleEvents, "CREATE_HOSPITAL_COMPONENT")
    }

    handleEvents = ({ type, payload }) => {
        if (type === "show_dialog") {
            this.setState({ isVisible: true })
        }
        else if (type === "close_dialog") {
            this.setState({ isVisible: false })
        }
    }

    onCreteCallback = (result) => {
        if (ValidResult(result)) {
            this.props.toastr.ShowSuccess("Successfully created the hospital")

            //we only filter if we successfully created the item
            this.state.internalEvents.defferedRiseEvent({ type: "filter_hospitals" })

            this.riseCloseEvent()
        }
        else {
            this.props.toastr.ShowError("Failed to create the hospital")
        }
    }

    onError = (err) => {
        this.props.toastr.ShowError("An error occurred, please try again")
    }

    riseCloseEvent = () => {
        this.state.internalEvents.riseEvent({ type: "close_dialog" })
    }

    render() {
        return (
            <Flyout
                onClose={() => { this.riseCloseEvent() }}
                isVisible={this.state.isVisible}
                id="edit-hospital-flout"
                showCloseButton={true}
                size="small">

                <FlyoutHead><h2 className="title-flyout">Create Hospital</h2></FlyoutHead>
                <FlyoutBody>
                    <SaveHospitalForm
                        align="1-col"
                        onSaveCallback={this.onCreteCallback}
                        onError={this.onError} />
                </FlyoutBody>
                <FlyoutFooter></FlyoutFooter>
            </Flyout>

        )
    }
}

export default CreateHospitalComponent
