import React, { Component } from 'react';
import HospitalTable from './HospitalTable';
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import MultiselectTagsFilterEntry from '../filterAndPagination/components/MultiselectTagsFilterEntry';
import FilterHandler from '../filterAndPagination/FilterHandler';
import { HospitalTableContext } from '../contexts'

class HospitalTableWithFilter extends Component {
    render() {
        return (
            <>
                <FilterHandler context={HospitalTableContext}>
                    <TextFilterEntry name="Name" friendlyName="Name" />
                    <TextFilterEntry name="Email" friendlyName="Email" init="email" />
                    <TextFilterEntry name="Phone" friendlyName="Phone" init="phone" />
                    <MultiselectTagsFilterEntry name="Tags"
                        placeholder="Select Tag"
                        url="Tag/GetAll"
                    />
                </FilterHandler>
                <HospitalTable
                    {...this.props}
                />
            </>
        );
    }
}

export default HospitalTableWithFilter;