import React from "react";
import FormEntry from "../../form/FormEntry";
import { Form } from "../../form/Form";
import InputComponent from "../../components/InputComponent";
import SafeSimpleEvent from "../SafeSimpleEvent";
import EntitiesSelect2Component from "../EntitySelect2Component";

function SavePracticeForm(props) {
  const formEvents = SafeSimpleEvent();

  const hiddenData = {
    Id: props.practice ? props.practice.Id : null,
  };

  let optionsForDropdown =
    props.practice && props.practice.Surgeons
      ? props.practice.Surgeons.map((entity) => {
          return entity.Id;
        })
      : [];

  return (
    <Form
      name="PracticeViewModel"
      url="Practice/Save"
      containsFile={false}
      align={props.align ? props.align : "1-col"}
      dontClear={true}
      onSubmitStart={props.onSubmitStart}
      callback={props.onSaveCallback}
      externalEvents={formEvents}
      onError={props.onError}
      hiddenData={hiddenData}
    >
      <FormEntry
        name="Name"
        text="Name*"
        value={props.practice ? props.practice.Practice : null}
      >
        <InputComponent />
      </FormEntry>
      <FormEntry
        name="Manager.FirstName"
        text="Manager First Name*"
        value={props.practice ? props.practice.Manager.FirstName : null}
      >
        <InputComponent />
      </FormEntry>
      <FormEntry
        name="Manager.LastName"
        text="Manager Last Name*"
        value={props.practice ? props.practice.Manager.LastName : null}
      >
        <InputComponent />
      </FormEntry>
      <FormEntry
        name="Manager.Email"
        text="Manager Email*"
        value={props.practice ? props.practice.Manager.Email : null}
      >
        <InputComponent />
      </FormEntry>
      <FormEntry
        name="SurgeonIds"
        text="Surgeons*"
        value={optionsForDropdown ? optionsForDropdown : null}
        key="SurgeonsIdsEntry"
      >
        <EntitiesSelect2Component
          placeholder="Select Surgeon"
          url="Surgeon/GetAll"
        />
      </FormEntry>
    </Form>
  );
}

export default SavePracticeForm;
