import React, { Component } from 'react';
import PatientTable from './PatientTable';
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import FilterHandler from '../filterAndPagination/FilterHandler';
import EntitiesSelect2Component from '../EntitySelect2Component';
import MultiselectTagsFilterEntry from '../filterAndPagination/components/MultiselectTagsFilterEntry';
import FilterEntryWrapper from '../filterAndPagination/components/FilterEntryWrapper';

class PatientTableWithFilter extends Component {

    render() {
        return (
            <>
                <FilterHandler context={this.props.context}>
                    <TextFilterEntry name="Name" friendlyName="Name" />
                    <FilterEntryWrapper label="Surgeon">
                        <EntitiesSelect2Component
                            className="dropdown-single-select"
                            name="SurgeonId"
                            placeholder="Select Surgeon"
                            url="Surgeon/GetAll"
                            notMultiple={true}
                        />
                    </FilterEntryWrapper>
                    <TextFilterEntry name="Email" friendlyName="Email" />
                    <MultiselectTagsFilterEntry
                        name="Tags"
                        placeholder="Select Tag"
                        url="Tag/GetAll"
                    />
                </FilterHandler>

                <PatientTable
                    {...this.props}
                />
            </>
        );
    }
}

export default PatientTableWithFilter;