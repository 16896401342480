import axios from "axios";

//comment this for production env
//window.ENVIRONMENT_URL = 'http://suxeed.admin/api/'
const settings = {
  baseURL: window.ENVIRONMENT_URL,
  withCredentials: true,
  crossDomain: false,
};

export default axios.create(settings);
