import React from "react";
import FilterHandler from "./filterAndPagination/FilterHandler";

export const SiteContext = React.createContext();
export const PaginationContext = React.createContext();
export const HospitalTableContext = React.createContext(
  FilterHandler.BuildContextValue()
);
export const SurgeonTableContext = React.createContext(
  FilterHandler.BuildContextValue()
);
export const PatientTableContext = React.createContext(
  FilterHandler.BuildContextValue()
);
export const QuestionnaireTableContext = React.createContext(
  FilterHandler.BuildContextValue()
);
export const GalleryGridContext = React.createContext(
  FilterHandler.BuildContextValue()
);
export const TagsTableContext = React.createContext(
  FilterHandler.BuildContextValue()
);
export const PracticeTableContext = React.createContext(
  FilterHandler.BuildContextValue()
);
