import React, { useRef } from 'react';
import "../css/gallery.css";
import { GalleryItemType } from '../utils/Utils'

function GalleryImageComponent(props) {
    const ref = useRef(null)

    const onClick = () => {
        props.editItem(props.id,props.name, props.source, GalleryItemType.Image)
    }

    return (
        <div className="d-gallery-image-entry">
            <img
                ref={ref}
                src={props.source} onClick={props.handleClick}
                id={props.id}
                className={props.class}
                alt={props.name}
            />
            <div className="d-galley-item-container">
                <span onClick={() => {
                    ref && ref.current.click()
                }} className="gallery-item-title">{props.name}
                </span>
                { props.deleteResource ?
                    <span onClick={props.deleteResource} class='delete-gallery-item' id={props.id}> </span>
                    : <></>
                }
                {
                    props.galleryGrid ? 
                    <span onClick={onClick} class='edit-gallery-item' id={props.id} > </span>
                    : <></>
                }
            </div>
        </div>
    )
}

export default GalleryImageComponent;