import React, { Component, useContext, useReducer } from 'react';
import AxiosHelper from '../../utils/AxiosHelper';
import Tabs from '../tabs/Tabs';
import Tab from '../tabs/components/Tab';
import "../../css/base/basicTable.css";
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import { MoreActionsMenu, MoreActionsItem } from '../MoreActions';
import { EntityStatus } from '../../utils/Utils'
import { HospitalTableContext } from '../contexts'
import SafeSimpleEvent from '../SafeSimpleEvent';
import UpdateStatusDialogComponent from '../UpdateStatusDialogComponent';
import ListingTagsView from '../ListingTagsView';
import { Notifications } from "../../components/NotificationsComponent"

function _reducer(state, action) {
    switch (action.type) {
        case 'SET_DATA': {
            return {
                ...state,
                hospitals: action.payload.data,
                screenIsReady: true
            }
        }
        case 'UPDATE_STATUS_HOSPITAL': {
            return {
                ...state,
                showRemoveEntityModal: true,
                updateDialogAction: action.payload.updateDialogAction,
                hospitalToUpdate: action.payload.hospitalToUpdate,
            }
        }
        case 'CLOSE_UPDATE_STATUS_HOSPITAL': {
            return {
                ...state,
                hospitalToDelete: '',
                updateDialogAction: '',
                showRemoveEntityModal: false
            }
        }
        default:
            return state
    }
}

function _HospitalTable(props) {
    const [state, dispatch] = useReducer(_reducer, {
        hospitalId: '',
        showRemoveEntityModal: false,
        updateDialogAction: '',
        hospital: null
    })

    const hospitalTableContext = useContext(HospitalTableContext)
    const toastr = useContext(Notifications)

    const handleCommonEvents = ({ type, payload }) => {
        if (type == "filter_hospitals") {
            hospitalTableContext.filterEvent.riseEvent()
        }
    }

    const openEditHospital = (hospitalId) => {
        props.editHospitalProxy && props.editHospitalProxy.riseEvent({
            type: "show_edit_hospital_dialog",
            payload: hospitalId
        })
    }

    const handleCloseRemoveEntityModal = () => {
        dispatch({
            type: "CLOSE_UPDATE_STATUS_HOSPITAL"
        });
    }

    const cancelUpdate = () => {
        dispatch({
            type: "CLOSE_UPDATE_STATUS_HOSPITAL"
        });
    }

    const updateEntity = () => {
        if (state.updateDialogAction == 'Deactivate') {
            AxiosHelper.get("Hospital/DeleteHospital?id=" + state.hospitalToUpdate).then(response => {
                toastr.ShowSuccess("Successfully deactivated the hospital")
                hospitalTableContext.filterEvent.riseEvent();
            }).catch(function (err) {
                toastr.ShowError("Failed to deactivate the hospital")
                this.setState({
                });
            });
        }
        if (state.updateDialogAction == 'Activate') {
            AxiosHelper.get("Hospital/ActivateHospital?id=" + state.hospitalToUpdate).then(response => {
                toastr.ShowSuccess("Successfully activated the hospital")
                hospitalTableContext.filterEvent.riseEvent();
            }).catch(function (err) {
                toastr.ShowError("Failed to activate the hospital")
                this.setState({
                });
            });
        }

        dispatch({
            type: "CLOSE_UPDATE_STATUS_HOSPITAL"
        });
    }

    const removeHospital = (hospitalId) => {
        dispatch({
            type: "UPDATE_STATUS_HOSPITAL",
            payload: {
                updateDialogAction: "Deactivate",
                hospitalToUpdate: hospitalId,
            }
        })
    }

    const activateHospital = (hospitalId) => {
        dispatch({
            type: "UPDATE_STATUS_HOSPITAL",
            payload: {
                updateDialogAction: "Activate",
                hospitalToUpdate: hospitalId,
            }
        })
    }

    props.didMoundEvent.subscribe(() => {
        // hospitalTableContext.formData = {
        //     ...props.surgeonTableContext.formData
        // }

        if (props.editHospitalProxy) {
            props.editHospitalProxy.subscribe(handleCommonEvents, "HOSPITAL_TABLE_COMMON_EVENTS")
        }
        if (props.createHospitalProxy) {
            props.createHospitalProxy.subscribe(handleCommonEvents, "HOSPITAL_TABLE_COMMON_EVENTS")
        }
    })

    const handleMenuItemClick = (menuItemId, obj, activeIndex) => {
        if (menuItemId == "edit") {
            openEditHospital(obj.Id)
        }
        else if (menuItemId === "remove") {
            if (activeIndex === EntityStatus.Deleted) {
                activateHospital(obj.Id)
            }
            else {
                removeHospital(obj.Id)
            }
        }
    }

    const renderHospitalsTable = (items, id, activeIndex) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }

        return (
            <table className="d-table" data-more-actions>
                <thead>
                    <tr className="d-table-row">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Tags</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {items.map(hospital => {
                        return (
                            <tr className="d-table-row" key={hospital.Id}>
                                <td onClick={() => { openEditHospital(hospital.Id)}}>{hospital.Name}</td>
                                <td><a href={`mailto:${hospital.Email}`}>{hospital.Email}</a></td>
                                <td><a href={`tel:${hospital.Phone}`}>{hospital.Phone}</a></td>
                                <td>{hospital.Address}</td>
                                <td><ListingTagsView tags={hospital.Tags} /> </td>
                                <td>
                                    <MoreActionsMenu callback={handleMenuItemClick} activeIndex={activeIndex} >
                                        <MoreActionsItem text="Edit" id="edit" key={`${hospital.Id}-edit`} obj={hospital} />
                                        <MoreActionsItem text={activeIndex == EntityStatus.Deleted ? "Activate" : "Deactivate"} id="remove" key={`${hospital.Id}-remove`} obj={hospital} />
                                    </MoreActionsMenu>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        )
    };

    return (
        <>
            <Tabs startTabId="active-hospitals-tab">
                <Tab friendlyName="Active" id="active-hospitals-tab" >
                    <PaginationHandler
                        id="active-hospitals-pagination"
                        url="Hospital/GetHospitalsPage"
                        activeIndex={EntityStatus.Active}
                        processor={renderHospitalsTable}
                        context={HospitalTableContext} />
                </Tab>
                <Tab friendlyName="Inactive" id="inactive-hospitals-tab" >
                    <PaginationHandler
                        id="inactive-hospitals-pagination"
                        url="Hospital/GetHospitalsPage"
                        activeIndex={EntityStatus.Deleted}
                        processor={renderHospitalsTable}
                        context={HospitalTableContext} />
                </Tab>
            </Tabs>
            <UpdateStatusDialogComponent
                showRemoveEntityModal={state.showRemoveEntityModal}
                handleClose={handleCloseRemoveEntityModal}
                entity="Hospital"
                action={state.updateDialogAction}
                cancelUpdate={cancelUpdate}
                updateEntity={updateEntity}
            />
        </>
    );
}

class HospitalTable extends Component {
    constructor(props) {
        super(props)

        this.didMoundEvent = SafeSimpleEvent()
    }

    componentDidMount(props) {
        this.didMoundEvent.riseEvent()
    }

    render() {
        return (
            <_HospitalTable {...this.props} didMoundEvent={this.didMoundEvent} />
        );
    }
}

export default HospitalTable;