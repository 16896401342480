import React, { useContext } from "react";
import SavePracticeForm from "./forms/SavePracticeForm";
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from "./Flyout";
import ObjectWrapper from "./ObjectWrapper";
import { Notifications } from "./NotificationsComponent";
import { ValidResult } from "../utils/Utils";

function _SavePracticeDialogComponent(props) {
  const toastr = useContext(Notifications);
  props.toastr.setObject(toastr);
  return null;
}

class SavePracticeDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      practice: {
        Practice: "",
        Manager: {
          FirstName: "",
          LastName: "",
          Email: "",
        },
        Surgeons: [],
      },
      showEditPracticeModal: props.showEditPracticeModal,
    };

    this.toastr = ObjectWrapper();
  }

  onSaveCallback = (result) => {
    if (ValidResult(result)) {
      this.toastr.ShowSuccess("Successfully saved the practice");
      this.props.handleClose();
      this.props.onSave();
    } else {
      this.toastr.ShowError("Failed to save the practice");
    }
  };

  componentWillMount() {
    this.setState({ showEditPracticeModal: false });
  }

  componentWillReceiveProps({ practice, showEditPracticeModal }) {
    if (!practice) {
      practice = this.state.practice;
    }
    this.setState({
      practice,
      showEditPracticeModal,
    });
  }

  render() {
    var state = this.state;
    var props = this.props;
    return (
      <>
        <_SavePracticeDialogComponent toastr={this.toastr} />
        <Flyout
          onClose={() => {
            this.props.handleClose();
          }}
          isVisible={state.showEditPracticeModal}
          id="edit-practice-flout"
          showCloseButton={true}
          size="small"
        >
          <FlyoutHead>
            <h2 className="title-flyout">Save practice</h2>
          </FlyoutHead>
          <FlyoutBody>
            <SavePracticeForm
              practice={state.practice}
              onSaveCallback={this.onSaveCallback}
              onError={this.onError}
            />
          </FlyoutBody>
          <FlyoutFooter> </FlyoutFooter>
        </Flyout>
      </>
    );
  }
}

export default SavePracticeDialogComponent;
