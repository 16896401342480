import React, { useReducer, useContext } from 'react'
import { Form } from '../form/Form';
import FormEntry from '../form/FormEntry';
import InputComponent from "./InputComponent"
import EntitiesSelect2Component from './EntitySelect2Component';

import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from "../components/Flyout"
import SafeSimpleEvent from './SafeSimpleEvent';
import { Notifications } from './NotificationsComponent';
import { ValidResult } from '../utils/Utils';
import EntitiesSelectComponent from './EntitySelectComponent';
import DialogManager from "./DialogManager"
import { debug } from 'util';

function _reducer(state, { type, payload }) {
    switch (type) {
        case "SET_VISIBLE":
            return {
                ...state,
                showModal: payload,
            }
        case "SET_DATA": {
            return {
                ...state,
                ...payload
            }
        }
        default:
            return state
    }
}
export default function SavePathwayDialog(props) {
    const [state, dispatch] = useReducer(_reducer, {
        showModal: false,
        isEdit: props.isEdit ? props.isEdit : false,
        isReadonly: props.isReadonly ? props.isReadonly : false,
        Id: props.id ? props.id : null,
        Name: "",
        IsTemplate: false,
        Type: 0,
        TagIds: [],

        Types: [
            { key: 0, text: "Bronze", value: 0 },
            { key: 1, text: "Silver", value: 1 },
            { key: 2, text: "Gold", value: 2 },
        ],
        internalEvents: SafeSimpleEvent(),
        formEvents: SafeSimpleEvent()
    })

    const toastr = useContext(Notifications)

    state.internalEvents.subscribe(({ type, payload }) => {
        if (type === "show") {
            !state.showModal && show()
        }
        else if (type === "hide") {
            state.showModal && hide()
        }
        else if (type === "edit") {
            !state.showModal && edit(payload)
        }
    }, "SAVE_PATHWAY_DIALOG")

    if (props.savePathwayProxy) {
        props.savePathwayProxy.setEvent(state.internalEvents)
    }

    const edit = (data) => {
        const ids = data.Tags.map((d) => d.Id)
        dispatch({
            type: "SET_DATA",
            payload: {
                ...data,
                TagIds: ids,
            }
        })

        show()
    }

    const hide = () => {
        dispatch({ type: "SET_VISIBLE", payload: false })
    }

    const show = () => {
        dispatch({ type: "SET_VISIBLE", payload: true })
    }

    const clearState = () => {
    }

    const handleClose = () => {
        hide()
        if (props.onClose) {
            props.onClose()
        }
    }

    const onSaveCallback = (result) => {
        if (ValidResult(result)) {
            toastr.ShowSuccess(`Successfuly ${state.isEdit ? "saved" : "created"} the pathway`)
            const { Data } = result;

            !state.isEdit && props.history.push(`/pathways/pathwaydetails?pathwayId=${Data}`)

        }
        else {
            toastr.ShowError(`Failed to ${state.isEdit ? "save" : "create"} the pathway`)
        }

        handleClose()
    }

    const onError = (err) => {
        toastr.ShowError("An error occurred, please try again")
    }
    return (
        <Flyout
            isVisible={state.showModal}
            id="save-pathway-flyout"
            showCloseButton={true}
            size="small"
            onClose={() => { handleClose() }}>
            <FlyoutHead><h2 className="title-flyout">{`${state.isEdit ? "Edit" : "Create"} Pathway`}</h2></FlyoutHead>
            <FlyoutBody>
                <Form name="PathwayViewUIModel" url="Pathway/Save" dontClear={true}
                    callback={onSaveCallback} onError={onError} hiddenData={{ Id: props.pathwayId }}
                    externalEvents={state.formEvents}
                    isReadonly={state.isReadonly}
                >
                    <FormEntry name="Name" text="Name*" value={state.Name} key="NameEntry" >
                        <InputComponent key="Name" />
                    </FormEntry>
                    <FormEntry name="Type" text="Type" value={state.Type} key="TypeEntry" value={state.Type} >
                        <EntitiesSelectComponent options={state.Types} />
                    </FormEntry>
                    <FormEntry
                        name="TagIds"
                        action={(
                            <span onClick={
                                () => {
                                    DialogManager.Show(
                                        "tag_dialog",
                                        ({ type, payload }) => {
                                            if (type === "save_tag_success") {
                                                state.formEvents.riseEvent({
                                                    type: "TagIds_add_option",
                                                    payload: {
                                                        key: payload.Id,
                                                        text: payload.Name,
                                                        value: payload.Id
                                                    }
                                                })
                                            }
                                        }
                                    )
                                }
                            }>+Create Tag</span>
                        )}
                        text="Tags*" value={state.TagIds} key="TagsIdsEntry">
                        <EntitiesSelect2Component
                            placeholder="Select Tags"
                            url="Tag/GetAll"
                        />
                    </FormEntry>
                </Form>
            </FlyoutBody>
            <FlyoutFooter></FlyoutFooter>
        </Flyout>
    )
}