import $ from "jquery";
//window.ENVIRONMENT_URL = 'http://suxeed.admin/api/'

var AjaxHelper = (function () {
  function postFile(url, formData, successCallback, failureCallBack) {
    $.ajax({
      type: "POST",
      url: window.ENVIRONMENT_URL + url,
      data: formData,
      dataType: "json",
      contentType: false,
      xhrFields: {
        withCredentials: true,
      },
      crossDomain: true,
      processData: false,
      success: successCallback,
      failure: failureCallBack,
    });
  }

  return {
    postFile: postFile,
  };
})();

export default AjaxHelper;
