import React from 'react';
import FormEntry from '../../form/FormEntry';
import { Form } from '../../form/Form';
import InputComponent from '../../components/InputComponent';
import EntitiesSelect2Component from '../EntitySelect2Component';
import SafeSimpleEvent from '../SafeSimpleEvent';
import DialogManager from "../DialogManager"

function SaveSurgeonForm(props) {
    const formEvents = SafeSimpleEvent()

    const hiddenData = {
        Id: props.surgeon ? props.surgeon.id : null,
    }

    if (props.surgeon) {
        hiddenData.HospitalIds = props.surgeon ? props.surgeon.hospitals : null
    }

    return (
        <Form
            name="SurgeonUIModel"
            url="Surgeon/Save"
            containsFile={false}
            align={props.align ? props.align : "1-col"}
            dontClear={true}
            onSubmitStart={props.onSubmitStart}
            callback={props.onSaveCallback}
            externalEvents={formEvents}
            onError={props.onError}
            hiddenData={hiddenData}>
            <FormEntry name="FirstName" text="First Name*" value={props.surgeon ? props.surgeon.firstName : null} >
                <InputComponent />
            </FormEntry>
            <FormEntry name="LastName" text="Last Name*" value={props.surgeon ? props.surgeon.lastName : null} >
                <InputComponent />
            </FormEntry>
            <FormEntry name="Email" text="Email*" value={props.surgeon ? props.surgeon.email : null} >
                <InputComponent />
            </FormEntry>
            <FormEntry name="Phone" text="Phone" value={props.surgeon ? props.surgeon.phone : null} >
                <InputComponent />
            </FormEntry>
            <FormEntry name="Address" text="Address" value={props.surgeon ? props.surgeon.address : null} >
                <InputComponent />
            </FormEntry>
            <FormEntry name="Website" text="Website" value={props.surgeon ? props.surgeon.website : null} >
                <InputComponent />
            </FormEntry>
            <FormEntry
                name="TagIds"
                action={(
                    <span onClick={
                        () => {
                            DialogManager.Show(
                                "tag_dialog",
                                ({ type, payload }) => {
                                    if (type === "save_tag_success") {
                                        formEvents.riseEvent({
                                            type: "TagIds_add_option",
                                            payload: {
                                                key: payload.Id,
                                                text: payload.Name,
                                                value: payload.Id
                                            }
                                        })
                                    }
                                }
                            )
                        }
                    }>+Create Tag</span>
                )}
                text="Tags*" value={props.surgeon ? props.surgeon.tags : null} key="TagsIdsEntry">
                <EntitiesSelect2Component
                    placeholder="Select Tags"
                    url="Tag/GetAll"
                />
            </FormEntry>
            < FormEntry name="HospitalIds" text="Hospitals*" value={props.surgeon ? props.surgeon.hospitals : null} >
                <EntitiesSelect2Component
                    placeholder="Select Hospital"
                    url="Hospital/GetAll"
                />
            </FormEntry>
        </Form>
    )
}

export default SaveSurgeonForm


