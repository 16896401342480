import React, { Component } from 'react'
import SafeSimpleEvent from '../SafeSimpleEvent';
import InputComponent from '../InputComponent';
import { Form } from '../../form/Form';
import FormEntry from '../../form/FormEntry';
import TextAreaComponent from '../TextAreaComponent';
import uuid from "uuid"

export class QuestionOption extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEdit: false,
            internalEvents: SafeSimpleEvent(),
        }

        if (this.props.externalEvents) {
            this.props.externalEvents.subscribe(this.handleEvents, `${this.props.value.InternalId}_option`)
        }

        this.state.internalEvents.subscribe(this.handleEvents, "ME")
    }

    handleEvents = ({ type, payload }) => {
        switch (type) {
            case "option_collect_data": {
                const data = {}
                let isValid = true

                var result = this.state.internalEvents.riseEventCheckSpecificResult({
                    type: "QuestionnaireOptionForm_collect_data",
                    payload: {}
                }, false)
                if (result) {
                    isValid = false
                }

                this.state.internalEvents.riseEvent({
                    type: "QuestionnaireOptionForm_collect_data_no_validation",
                    payload: data
                })

                data.Id = this.props.value.Id
                data.InternalId = this.props.value.InternalId

                payload.push(data)
                return isValid
            }
            case "on_will_destroy_option": {
                if (payload && payload !== this.props.value.InternalId) {
                    return false
                }
                this.state.internalEvents.clear()
                return true
            } break
            default:
                break;
        }
    }

    render() {
        return (
            <div className="s-question-option">
                <label className="s-question-option-remove" title="Remove Option" onClick={
                    () => {
                        this.props.externalEvents.riseEvent({
                            type: "remove_option",
                            payload: this.props.value.InternalId
                        })
                    }
                }></label>
                <Form
                    id={`QuestionnaireOptionForm_${this.props.value.InternalId}`}
                    name="QuestionnaireOptionForm"
                    collectIfNotValid={true}
                    externalEvents={this.state.internalEvents}
                    align="custom">
                    <FormEntry text={`Choice*`} name="Title" value={this.props.value.Title} placeholder="Title">
                        <InputComponent />
                    </FormEntry>
                    <FormEntry text="Score - between 0 and 100*" name="Weight" value={this.props.value.Weight}>
                        <InputComponent />
                    </FormEntry>
                    <FormEntry text="Message" name="Message" value={this.props.value.Message} placeholder="Type advice">
                        <TextAreaComponent />
                    </FormEntry>
                </Form>
            </div>
        )
    }
}

export default QuestionOption
