import React, { Component } from 'react';
import ImageUpload from './ImageUpload';
import GalleryDialogComponent from './GalleryDialogComponent';
import SafeSimpleEvent from './SafeSimpleEvent';
import { ToArray, GalleryItemType } from '../utils/Utils';
import VideoUpload from './VideoUpload';
import UpdateStatusDialogComponent from '../components/UpdateStatusDialogComponent';

import "../css/basicGalleryPicker.css"

class GalleryItemPickerComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            url: this.props.url ? this.props.url : (this.props.value ? this.props.value : ""),
            file: null,
            showGalleryEvent: SafeSimpleEvent(),
            showUploadEvent: SafeSimpleEvent(),
            showPickFromGallery: props.showPickFromGallery !== undefined ? props.showPickFromGallery : true,
            showUpdateEntityModal: false,
            internalEventHandler: SafeSimpleEvent(),
            name: this.props.name

        }
        console.log(this.state.url)
        if (this.props.collectDataEvent) {
            this.props.collectDataEvent.subscribe(this.collectData, this.props.name)
        }

        if (this.props.validateEvent) {
            this.props.validateEvent.subscribe(this.validateCallback, this.props.name)
        }

        if (this.props.externalEvents) {
            props.externalEvents.subscribe(this.handleEvent, this.props.name)
        }
    }

    handleEvent = ({ type, payload }) => {
        switch (type) {
            case "REMOVE_VIDEO_COVER": {
                if (this.state.name == "VideoCaption") {
                    this.setState({ url: "" })
                }
            }
            break
            default: { } break
        }
    }

    collectData = (formData) => {
        const urlPostName = this.props.urlPostName ? this.props.urlPostName : `${this.props.name}Url`

        if (this.state.file) {
            if (this.props.postBas64) {
                formData[`${this.props.name}File`] = this.state.url;
            }
            else {
                formData[`${this.props.name}File`] = this.state.file;
            }

            if (this.isRequired) {

                formData[urlPostName] = "dummy";
            }
            else {
                formData[urlPostName] = null;
            }
        }
        else {
            formData[`${this.props.name}File`] = null;
            formData[urlPostName] = this.state.url;
        }
    }

    validateCallback = () => {
        if (this.props.setValidationMessageEvent && Array.isArray(this.props.rules)) {

            var requiredRule = this.props.rules.find((r) => r.ValidationType === "required");
            if (requiredRule) {

                this.isRequired = true

                const errMessage = `Please select ${this.props.type === GalleryItemType.Image ? "an image" : "a video"}`

                if (!this.state.file && (!this.state.url || this.state.url.trim() === "")) {
                    this.props.setValidationMessageEvent.riseEvent(errMessage)
                    return false
                }
            }
        }

        this.props.setValidationMessageEvent.riseEvent("")
        return true
    }

    handleClickCallbackCover = (url, file) => {
        this.setState({
            url,
            file,
        });

        console.log(this.state.url)
    };

    hideModal = () => {
        this.setState({
            showUpdateEntityModal: false
        })
    }

    updateEntity = () => {
        if (this.state.name == "Video") {
            this.props.externalEvents.riseEvent({ type: "REMOVE_VIDEO_COVER" })
        }

        this.setState({
            url: "",
            showUpdateEntityModal: false,
        })
    }

    showModal = () => {
        this.setState({
            showUpdateEntityModal: true
        })
    }

    render() {
        const state = this.state;
        const props = this.props;
        return (
            <>
                <UpdateStatusDialogComponent
                    showRemoveEntityModal={state.showUpdateEntityModal}
                    handleClose={this.hideModal}
                    entity="media resource"
                    action="Remove"
                    cancelUpdate={this.hideModal}
                    updateEntity={this.updateEntity}
                    legend={this.props.legend}
                />

                <div className="d-image-gallery">
                    <GalleryDialogComponent showGalleryEvent={this.state.showGalleryEvent} dialogTitle={props.dialogTitle} handleClickCallBack={this.handleClickCallbackCover} type={props.type} />
                    {props.type == GalleryItemType.Image ?
                        <ImageUpload showEvent={this.state.showUploadEvent} handleImageChange={this.handleClickCallbackCover} url={this.state.url} />
                        :
                        props.type == GalleryItemType.Video ?
                            <VideoUpload showEvent={this.state.showUploadEvent} handleVideoChange={this.handleClickCallbackCover} url={this.state.url} /> :
                            <></>
                    }
                    {
                        !this.props.isReadonly ?
                            <div className="d-image-gallery-panel">
                                {this.props.hasRemove && this.state.url != '' && this.state.url != 'removed' ?
                                    <a onClick={this.showModal} className="d-remove-picture">Remove</a>
                                    : <></>
                                }
                                {state.showPickFromGallery ?
                                    <>
                                        <a onClick={() => this.state.showGalleryEvent.riseEvent(true)}>Pick from Gallery</a>
                                        <span> / </span>
                                    </> : null}
                                <a onClick={() => {
                                    this.state.showUploadEvent.riseEvent()
                                }}>Upload</a>

                            </div>
                            : <></>

                    }
                </div>
            </>
        )
    }
}

export default GalleryItemPickerComponent;