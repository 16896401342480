import React, { Component, useContext } from 'react'
import "../css/base/cssReset.css";
import "../css/base/basicStructure.css";
import "../css/fonts.css"
import FormEntry from '../form/FormEntry';
import InputComponent from '../components/InputComponent';
import { Form } from '../form/Form';
import SiteState from '../components/SiteState';
import { PageStateHandler } from '../components/PageState';

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props)
    }

    onSubmitSuccess =  (result) => {

        if (result.Success == true) {
            this.props.toastr.ShowSuccess("We've sent you an email to reset your password")
            PageStateHandler.SetLoading();
            setTimeout( () => 
            this.props.SetSiteState(SiteState.LoggedOut), 1500)      
        }
        else {
            this.props.toastr.ShowError("An error occurred, please try again")
        }
    }

    onError =  () => {
          this.props.toastr.ShowError("An error occurred, please try again")
    }

    render() {
        return (
            <div className="reset-password-container">
                <div className="logo-resset-password">Forgot Password</div>
                <Form name="ForgotPasswordUIModel"
                    url="Account/ForgotPassword"
                    align="1-col"
                    dontClear={true}
                    callback={this.onSubmitSuccess}
                    containsFile={false}
                    onError={this.onError}
                >
                    <FormEntry name="Email" text="Email Address">
                        <InputComponent />
                    </FormEntry>
                </Form>
            </div >
        )
    }
}

export default ForgotPasswordPage
