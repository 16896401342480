import React, { Component } from 'react'
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from './Flyout';
import SaveSurgeonForm from './forms/SaveSurgeonForm';
import { ValidResult } from '../utils/Utils';

export class CreateSurgeonDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false
        }

        if (props.externalEvents) {
            props.externalEvents.subscribe(this.handleEvents, "CREATE_SURGEON_DIALOG")
        }
    }

    show = () => {
        this.setState({ isVisible: true })
    }

    hide = () => {
        this.setState({ isVisible: false })
    }

    handleEvents = ({ type, payload }) => {
        if (type === "create_surgeon_dialog_show") {
            this.show()
        }
    }

    onSaveCallback = (result) => {
        if (ValidResult(result)) {
            this.props.toastr.ShowSuccess("Successfully created the surgeon");

            this.props.externalEvents.riseEvent({
                type: "filter_surgeons"
            })

            this.hide()
        }
        else {
            this.props.toastr.ShowError("Failed to create the surgeon");
        }
    }

    onError = () => {
        this.props.toastr.ShowError("An error occurred, please try again");
    }

    handleClose = () => {
        this.hide()
    }

    render() {
        return (
            <Flyout onClose={() => { this.handleClose() }} isVisible={this.state.isVisible} id="create-hospital-flout" showCloseButton={true} size="small">
                <FlyoutHead><h2 className="title-flyout">Create Surgeon</h2></FlyoutHead>
                <FlyoutBody>
                    <SaveSurgeonForm
                        onSaveCallback={this.onSaveCallback}
                        onError={this.onError}
                    /></FlyoutBody>
                <FlyoutFooter> </FlyoutFooter>
            </Flyout>
        )
    }
}

export default CreateSurgeonDialog
