import React, { Component, useContext, useReducer } from 'react';
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import { TagsTableContext } from "../contexts"
import SafeSimpleEvent from '../SafeSimpleEvent';
import { EntityStatus } from '../../utils/Utils';
import Moment from 'react-moment';
import UpdateStatusDialogComponent from "../UpdateStatusDialogComponent"
import AxiosHelper from '../../utils/AxiosHelper';
import { Notifications } from "../../components/NotificationsComponent"

function _reducer(state, action) {
    switch (action.type) {
        case 'SET_STATE': {
            return {
                ...state,
                showDeleteModal: action.payload.showDeleteModal,
                tagToDelete: action.payload.tagToDelete,
                screenIsReady: true
            }
        }

        default:
            return state
    }
}

function _TagTable(props) {
    const [state, dispatch] = useReducer(_reducer, {
        showDeleteModal: false,
        tagToDelete: null,
    })

    const tagTableContext = useContext(TagsTableContext)
    const toastr = useContext(Notifications)

    const deleteTag = () => {
        if (state.tagToDelete != null) {
            AxiosHelper.post("Tag/DeleteTag?id=" + state.tagToDelete
            ).then((result) => {
                tagTableContext.filterEvent.riseEvent();
                toastr.ShowSuccess("Successfully deleted the tag")
                dispatch({
                    type: "SET_STATE",
                    payload: {
                        showDeleteModal: false,
                        tagToDelete: null,
                    }
                })
            }).catch((ex) => {
                toastr.ShowSuccess("Error deleting the tag")
            })
        }
    }

    const cancelDelete = () => {
        dispatch({
            type: "SET_STATE",
            payload: {
                showDeleteModal: false,
                tagToDelete: null,
            }
        })
    }

    const renderTagsTable = (items) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }
        return (
            <table className="d-table">
                <thead>
                    <tr className="d-table-row">
                        <th></th>
                        <th>Name</th>
                        <th>Saved by</th>
                        <th>Saved at</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(tag => {
                        return (
                            <tr className="d-table-row" key={tag.Id} >
                                <td></td>
                                <td>{tag.Name}</td>
                                <td>{tag.SavedBy}</td>
                                <td><Moment format="D.MM.YYYY HH:mm">
                                    {tag.SavedAt}
                                </Moment></td>
                                <td>
                                    <div onClick={() => {
                                        dispatch({
                                            type: "SET_STATE",
                                            payload: {
                                                showDeleteModal: true,
                                                tagToDelete: tag.Id,
                                            }
                                        })
                                    }} title="Delete" className="delete-tag"></div>
                                </td>
                                <td></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    return (
        <>
            <UpdateStatusDialogComponent
                action="Delete"
                entity="tag"
                updateEntity={deleteTag}
                cancelUpdate={cancelDelete}
                showRemoveEntityModal={state.showDeleteModal}
            />

            <PaginationHandler
                id="tags-pagination"
                url="Tag/GetTagsPage"
                processor={renderTagsTable}
                activeIndex={EntityStatus.Active}
                context={TagsTableContext}
            />
        </>
    );
}

class TagsTable extends Component {
    constructor(props) {
        super(props)
        this.didMountEvent = SafeSimpleEvent()
    }

    componentDidMount(props) {
        this.didMountEvent.riseEvent()
    }

    render() {
        return (
            <_TagTable {...this.props} didMoundEvent={this.didMoundEvent} />
        );
    }
}

export default TagsTable;