import moment from "moment";

const DATE_PATTERN = /(\d+)/g   //used to capture the time from "/Date(1234567)/" formated dates

const ToArray = element => {
    if (!Array.isArray(element)) {
        return [element];
    }
    return element;
}

const EntityStatus = {
    Deleted: -1,
    Active: 0,
    Rejected: 1
}

const UserStatus = {
    Rejected : -1,
    Pending : 0,
    Approved : 1,
    Deleted : 2
}

const GalleryItemType = {
    Image: 0,
    Video: 1
}

export function Take(array, count, startIndex = 0) {
    if (!Array.isArray(array)) {
        return []
    }

    const result = []
    const remaining = (array.length - startIndex) - count
    if (remaining < 0) {
        count -= remaining
        if (!count) {
            return []
        }
    }

    for (let i = 0; i < count; i++) {
        result.push(array[startIndex + i])
    }

    return result
}

export function All(array, fn) {
    if (!Array.isArray(array)) {
        return false
    }

    array.forEach((i) => {
        if (!fn(i)) {
            return false
        }
    })

    return true
}

function ExtractParamsFromQueryString(url) {
    var params = {};
    if (!url) {
        return params;
    }
    var splitByQ = url.split('?');
    if (!splitByQ[1]) {
        return params;
    }
    var splitByA = splitByQ[1].split('&');
    splitByA.forEach(t => {
        const parts = t.split('=');
        params[parts[0]] = parts[1];
    })
    return params;
}

export function Where(array, fn) {
    if (!Array.isArray(array)) {
        return false
    }

    const result = []
    array.forEach((i) => {
        if (fn(i)) {
            result.push(i)
        }
    })

    return result
}

export function SelectArray(array, fn) {

}

export function ExtractIds(entityList) {
    let entityIds = entityList.map(entity => {
        return entity.Id;
    });
    return entityIds;
}

export function ValidResult(result) {
    return result && result.Success;
}

export function StrDateToMoment(str) {
    const valStr = str.match(DATE_PATTERN)[0]
    if (!valStr) {
        return null
    }

    return moment.unix(parseInt(valStr) / 1000)
}

function StopPropagation(ev) {
    ev.stopPropagation();
}

export { ToArray, EntityStatus, GalleryItemType, StopPropagation, ExtractParamsFromQueryString, UserStatus }
