import React, { Component, useReducer, useContext } from 'react';
import "antd/dist/antd.css";
import "./css/base/cssReset.css";
import "./css/base/basicStructure.css";
import { SiteContext } from './components/contexts';
import SafeSimpleEvent from './components/SafeSimpleEvent';
import AxiosHelper from './utils/AxiosHelper';
import LoginPage from './pages/LoginPage';
import LoadingPage from './pages/LoadingPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import SiteState from './components/SiteState';
import Root from './Root';

import translations from "./components/translations"
import fillTemplate from "es6-dynamic-template"

import "./css/fonts.css"
import { ValidResult } from './utils/Utils';
import NotificationsComponent, { Notifications } from './components/NotificationsComponent';
import PageState from './components/PageState';
import ConfirmModule from './components/ConfirmModule';
import { DialogManagerComponent } from './components/DialogManager';

const THEME_LINKS = {
    ["default"]: "/themes/default"
}

function SiteReducer(state, { type, payload }) {
    switch (type) {
        case "SET_TRANSLATION": {
            const newTranslation = state.translations.all.find((t) =>
                t.locale === payload.name && (!payload.version || payload.version === t.version))

            if (!newTranslation) {
                return state
            }

            return {
                ...state,
                activeTranslation: newTranslation
            }
        }
        case "SET_THEME":
            return {
                ...state,
                Theme: payload
            }
        case "SET_AUTH_STATE":
            return {
                ...state,
                screenIsReady: payload.screenIsReady,
                auth: {
                    ...state.auth,
                    siteState: payload.siteState
                },
                userData: payload.userData
            }
        case "LOGOUT":
            return {
                ...state,
                auth: {
                    ...state.auth,
                    siteState: SiteState.LoggedOut
                },
                userData: {}
            }
        case "SET_SITE_STATE":
            return {
                ...state,
                siteState: payload.siteState,
            }

        default:
            return state
    }
}

function SiteWrapper(props) {
    const [state, dispatch] = useReducer(SiteReducer, {
        Theme: "default",
        Themes: THEME_LINKS,
        auth: {
            siteState: SiteState.Loading,
            screenIsReady: false,
            userData: {}
        },
        translations: translations,
        activeTranslation: translations.active
    })

    props.didMountEvent.subscribe(() => {

        if (window.location.href.indexOf("Account/RessetPassword") !== -1) {
            //we are on resetpassword page

            dispatch({
                type: "SET_AUTH_STATE",
                payload: {
                    siteState: SiteState.RessetPasswordPage,
                    screenIsReady: true,
                    userData: {}
                }
            })

            return;
        }

        AxiosHelper.post('Account/IsAuthorized').then((response) => {
            if (ValidResult(response.data)) {
                dispatch({
                    type: "SET_AUTH_STATE",
                    payload: {
                        siteState: SiteState.LoggedIn,
                        screenIsReady: true,
                        userData: {
                            email: response.data.Data.Email,
                            name: response.data.Data.Name,
                            profileUrl: ""
                        }
                    }
                })
            }
            else {
                dispatch({
                    type: "SET_AUTH_STATE",
                    payload: {
                        siteState: SiteState.LoggedOut,
                        isAuthenticated: false,
                        screenIsReady: true,
                        userData: {}
                    }
                })
            }
            return true;
        }).catch(() => {
            dispatch({
                type: "SET_AUTH_STATE",
                payload: {
                    siteState: SiteState.LoggedOut,
                    isAuthenticated: false,
                    screenIsReady: true,
                    userData: {}
                }
            })
            //LogIn()
        });
    })

    const toastr = useContext(Notifications)

    const OnLoginSuccess = ({ Email, Name, ProfilePictureUrl }) => {
        setTimeout(() => {
            dispatch({
                type: "SET_AUTH_STATE",
                payload: {
                    siteState: SiteState.LoggedIn,
                    screenIsReady: true,
                    userData: {
                        email: Email,
                        name: Name,
                        profileUrl: ProfilePictureUrl
                    }
                }
            })
        }, 500);
    }

    const LogOut = () => {
        //make call to serve to logout then on success dispatch to the reducer the "LOGOUT" action
        AxiosHelper.post('Account/LogOff').then((response) => {
            if (ValidResult(response.data)) {
                dispatch({
                    type: "LOGOUT",
                    payload: {
                        reasone: "none"
                    }
                })
            }
        }).catch((err) => {
            dispatch({
                type: "LOGOUT",
                payload: {
                    reasone: "none"
                }
            })
        });

    }

    const SetSiteState = (state) => {
        dispatch({
            type: "SET_AUTH_STATE",
            payload: {
                siteState: state,
                screenIsReady: true,
                userData: {},
                auth: {}
            }
        })
    }

    const RenderLoggedIn = () => {
        return (
            <>
                <link rel="stylesheet" type="text/css" href={`${state.Themes[state.Theme]}/theme.css`} />
                <Root />
            </>)
    }

    const RenderLoggedOut = () => {
        return (
            <>
                <link rel="stylesheet" type="text/css" href={`${state.Themes[state.Theme]}/theme.css`} />
                <link rel="stylesheet" type="text/css" href={`${state.Themes[state.Theme]}/login.css`} />
                <link rel="stylesheet" type="text/css" href={`${state.Themes[state.Theme]}/loginAndRegister.css`} />
                <LoginPage SetSiteState={SetSiteState}/>
            </>)
    }

    const RederRessetPasswordPage = () => {
        return (
            <>
                <link rel="stylesheet" type="text/css" href={`${state.Themes[state.Theme]}/theme.css`} />
                <link rel="stylesheet" type="text/css" href={`${state.Themes[state.Theme]}/loginAndRegister.css`} />
                <ResetPasswordPage toastr={toastr} SetSiteState={SetSiteState} />
            </>
        )
    }

    const RederForgotPasswordPage = () => {
        return (
            <>
                <link rel="stylesheet" type="text/css" href={`${state.Themes[state.Theme]}/theme.css`} />
                <link rel="stylesheet" type="text/css" href={`${state.Themes[state.Theme]}/loginAndRegister.css`} />
                <ForgotPasswordPage toastr={toastr} SetSiteState={SetSiteState} />
            </>
        )
    }

    const RenderLoading = () => (
        <>
            <LoadingPage />
        </>
    )

    const Render = () => {
        if (!state.screenIsReady) {
            return RenderLoading()
        }

        switch (state.auth.siteState) {
            case SiteState.LoggedIn:
                return RenderLoggedIn()
            case SiteState.LoggedOut:
                return RenderLoggedOut()
            case SiteState.Loading:
                return RenderLoading()
            case SiteState.RessetPasswordPage:
                return RederRessetPasswordPage()
            case SiteState.ForgotPassword:
                return RederForgotPasswordPage()
            default:
                return RenderLoading()
        }
    }

    const GetTranslationKey = (key, def, group, props) => {
        const activeTranslation = state.activeTranslation

        if (activeTranslation.locale === "default") {
            return def ? def : "No default value set for [" + key + "]"
        }

        if (!group) {
            group = "General"
        }

        if (!activeTranslation[group]) {
            return activeTranslation.locale + " dosn't contain group [" + group + "]"
        }

        let value = activeTranslation[group][key];

        value = value ? value : def

        if (props && value !== def) {
            value = fillTemplate(value, props)
        }

        return value
    }

    const TranslateValidation = (field, fieldName) => {
        let result = {}

        const validations = field.ValidationRules
        const rules = field.Rules

        let args = {
            fieldName
        }

        rules.forEach(rule => {
            const keys = Object.keys(rule.ValidationParameters)
            if (!keys || !keys.length) {
                return
            }

            keys.forEach((key) => {
                args[key] = rule.ValidationParameters[key]
            })
        });

        for (var key in validations) {
            if (key === "data-val-regex-pattern") {
                result[key] = validations[key]
                continue
            }

            try {
                result[key] = GetTranslationKey(key, validations[key], "Validations", args)
            }
            catch (ex) {
            }
        }

        return result
    }

    return (
        <SiteContext.Provider value={{ dispatch, state, LogOut, OnLoginSuccess }}>
            {
                <>
                    <NotificationsComponent />
                    <ConfirmModule />
                    <DialogManagerComponent />
                    <PageState>
                        {Render()}
                    </PageState>
                </>
            }
        </SiteContext.Provider>
    )
}

class App extends Component {
    state = {
        didMountEvent: SafeSimpleEvent()
    }

    componentDidMount = () => {
        this.state.didMountEvent.riseEvent()
    }

    render() {
        return (<SiteWrapper didMountEvent={this.state.didMountEvent} />);
    }
}

export default App;
