import React, { Component } from "react";
import { Take, ToArray } from "../utils/Utils";

import "../css/listingTagsView.css";

export class ListingSurgeonsView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    const surgeons = Take(props.surgeons, 2);
    const leftoverCount = props.surgeons.length
      ? props.surgeons.length - surgeons.length
      : 0;
    const showLeftover = leftoverCount > 0;

    return (
      <div className="listing-tags-container">
        {surgeons.map((surgeon) => {
          return surgeon ? <div className="d-tag">{surgeon.Name}</div> : null;
        })}
        {showLeftover ? (
          <label className="leftover">
            {"  +" + leftoverCount}
            <div className="leftover-view">
              {props.surgeons.slice(2, props.surgeons.length).map((surgeon) => {
                return <div className="d-tag">{surgeon.Name}</div>;
              })}
            </div>
          </label>
        ) : null}
      </div>
    );
  }
}

export default ListingSurgeonsView;
