import React, { Component, useReducer, useContext } from 'react';
import Tabs from '../tabs/Tabs';
import Tab from '../tabs/components/Tab';
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import { UserStatus } from '../../utils/Utils';
import SafeSimpleEvent from '../SafeSimpleEvent';
import { PatientTableContext } from "../contexts"
import AxiosHelper from '../../utils/AxiosHelper';

import SavePatientAnswersComponent from '../SavePatientAnswersComponent';

import { MoreAction, MoreActionsMenu, MoreActionsItem } from '../MoreActions';
import "../../css/base/basicTable.css";
import { Notifications } from '../NotificationsComponent';

function _reducer(state, action) {
    switch (action.type) {
        case 'SET_DATA': {
            return {
                ...state,
                patients: action.payload.data,
                screenIsReady: true
            }
        }
        case 'EDIT_PATIENT': {
            return {
                ...state,
                showEditPatientModal: true,
                patientId: action.payload.patientId,
                patient: action.payload.patient
            }
        }

        case 'SHOW_PATIENT_ANSWER_MODAL' : {
            return {
                ...state,
                showPatientAnswer: true,
                patient: {
                    "id": action.payload.patientId,
                    "name":action.payload.patientName,
                }
            }
        }
        case 'CLOSE_PATIENT_ANSWER': {
            return {
                ...state,
                showPatientAnswer: false
            }
        }
        case 'CLOSE_EDIT_PATIENT': {
            return {
                ...state,
                patientId: '',
                showEditPatientModal: false
            }
        }
        case 'UPDATE_STATUS_PATIENT': {
            return {
                ...state,
                showUpdateEntityModal: true,
                updateDialogAction: action.payload.updateDialogAction,
                patientToUpdate: action.payload.patientToUpdate,
            }
        }
        case 'CLOSE_UPDATE_STATUS_PATIENT': {
            return {
                ...state,
                patientToUpdate: '',
                showUpdateEntityModal: false
            }
        }
        default:
            return state
    }
}
function _PatientTable(props) {
    const [state, dispatch] = useReducer(_reducer, {
        showEditPatientModal: false,
        patient: null,
        patientId: '',
        patientToUpdate: '',
        updateDialogAction: '',
        showPatientAnswer: false,
        showUpdateEntityModal: false
    })

    const patientTableContext = useContext(PatientTableContext);
    const toastr = useContext(Notifications)

    const handleEditPatient = (id) => {
        var patientId = id;
        AxiosHelper.get("Patient/GetPatient?id=" + patientId).then(response => {
            let tags = response.data.Tags.map(tag => {
                return tag.Id;
            });
            dispatch({
                type: "EDIT_PATIENT",
                payload: {
                    patientId: patientId,
                    patient: {
                        id: response.data.Id,
                        surgeonId: response.data.SurgeonId,
                        firstName: response.data.FirstName,
                        lastName: response.data.LastName,
                        email: response.data.Email,
                        phone: response.data.PhoneNumber,
                        dateOfBirth: response.data.DateOfBirth,
                        dateOfSurgery: response.data.DateOfSurgery,
                        surgeryTime: "",
                        activePathwayId: response.data.ActivePathwayId,
                        code: response.data.Code,
                        tags: tags
                    }
                },
            });
        }).catch(function (err) {

        });
    }

    const cancelUpdate = () => {
        dispatch({
            type: "CLOSE_UPDATE_STATUS_PATIENT"
        });
    }

    const updateEntity = () => {
        if (state.updateDialogAction == 'Deactivate') {
            AxiosHelper.get("Patient/DeletePatient?id=" + state.patientToUpdate).then(response => {
                toastr.ShowSuccess("Successfully deactivated the patient")
                patientTableContext.filterEvent.riseEvent();
            }).catch(function (err) {
                toastr.ShowError("Failed to deactivate the patient")
                this.setState({
                });
            });
        }
        if (state.updateDialogAction == 'Activate') {
            AxiosHelper.get("Patient/ActivatePatient?id=" + state.patientToUpdate).then(response => {
                toastr.ShowSuccess("Successfully activated the patient")
                patientTableContext.filterEvent.riseEvent();
            }).catch(function (err) {
                toastr.ShowError("Failed to activate the patient")
                this.setState({
                });
            });
        }

        dispatch({
            type: "CLOSE_UPDATE_STATUS_PATIENT"
        });
    }

    const handleCloseUpdateEntityModal = () => {
        dispatch({
            type: "CLOSE_UPDATE_STATUS_PATIENT"
        });
    }

    const handleCloseEditPatientModal = () => {
        dispatch({
            type: "CLOSE_EDIT_PATIENT"
        });
        patientTableContext.filterEvent.riseEvent();
    };

    const handleClosePatientAnswer = () => {
        dispatch({
            type: "CLOSE_PATIENT_ANSWER"
        });
    };

    const handleRemovePatient = (patientId) => {
        dispatch({
            type: "UPDATE_STATUS_PATIENT",
            payload: {
                updateDialogAction: "Deactivate",
                patientToUpdate: patientId,
            }
        })
    }

    const handleActivatePatient = (patientId) => {
        dispatch({
            type: "UPDATE_STATUS_PATIENT",
            payload: {
                updateDialogAction: "Activate",
                patientToUpdate: patientId,
            }
        })
    }

    const handleShowPatientAnswer = (patientId, name) => {
        dispatch({
            type: "SHOW_PATIENT_ANSWER_MODAL",
            payload: {
                patientId: patientId,
                patientName: name
            }
        })
    }

    props.willMoundEvent.subscribe(() => {
        patientTableContext.formData = {
            ...props.surgeonTableContext.formData
        }
    })

    const handleMenuItemClick = (menuItemId, obj, activeIndex) => {
        if (menuItemId == "questionnaires") {
            handleShowPatientAnswer(obj.Id, obj.Name)
        }
        else if (menuItemId == "edit") {
            handleEditPatient(obj.Id)
        }
        else if (menuItemId == "remove") {
            if (activeIndex == UserStatus.Deleted || activeIndex == UserStatus.Pending) {
                handleActivatePatient(obj.Id)
            }
            else {
                handleRemovePatient(obj.Id)
            }

        }
    }

    const renderPatientsTable = (items, id, activeIndex) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }

        return (
            <table className="d-table" data-more-actions >
                <thead>
                    <tr className="d-table-row">
                        <th>Name</th>
                        <th>Surgeon</th>
                        <th>Pathway</th>
                        <th>Code</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, i) =>
                            (
                                <tr className="d-table-row" key={item.Id}>
                                    <td onClick={() => { handleEditPatient(item.Id) }}  >{item.Name}</td>
                                    <td>{item.SurgeonName}</td>
                                    <td>{item.Pathway}</td>
                                    <td>{item.Code}</td>
                                    <td><a href={`mailto:${item.Email}`} >{item.Email}</a></td>
                                    <td><a href={`tel:${item.Phone}`} >{item.Phone}</a></td>
                                    <td className="padding-right-20 padding-top-0" onClick={() => { handleShowPatientAnswer(item.Id, item.Name) }}>
                                        <div title="Questionnaires" className="questionnaire"></div>
                                    </td>
                                </tr>
                            )
                        )
                    }
                </tbody>
            </table>)
    }

    return (
        <>
            <Tabs startTabId="active-patients-tab">
                <Tab friendlyName="Pending" id="pending-patients-tab" >
                    <PaginationHandler
                        context={PatientTableContext}
                        id="inactive-patients-pagination"
                        url="/Patient/GetPatientsPage"
                        activeIndex={UserStatus.Pending}
                        processor={renderPatientsTable} />
                </Tab>
                <Tab friendlyName="Active" id="active-patients-tab" >
                    <PaginationHandler
                        context={PatientTableContext}
                        id="active-patients-pagination"
                        url="/Patient/GetPatientsPage"
                        activeIndex={UserStatus.Approved}
                        processor={renderPatientsTable} />
                </Tab>
                <Tab friendlyName="Inactive" id="inactive-patients-tab" >
                    <PaginationHandler
                        context={PatientTableContext}
                        id="inactive-patients-pagination"
                        url="/Patient/GetPatientsPage"
                        activeIndex={UserStatus.Deleted}
                        processor={renderPatientsTable} />
                </Tab>
            </Tabs>
          

            <SavePatientAnswersComponent
                showPatientAnswer={state.showPatientAnswer}
                handleClose={handleClosePatientAnswer}
                patient={state.patient}
                toastr={toastr}
            />
         
        </>
    )
}

class PatientTable extends Component {
    constructor(props) {
        super(props)

        this.willMoundEvent = SafeSimpleEvent()
    }


    componentWillMount(props) {
        this.willMoundEvent.riseEvent()
    }

    render() {
        return (
            <_PatientTable {...this.props} willMoundEvent={this.willMoundEvent} />
        );
    }
}

export default PatientTable;