import React, { Component, useContext, useReducer } from "react";
import PageHeading, { PageTitle, ActionGroup } from "../components/PageHeading";
import PrimaryButton from "../components/buttons/PrimaryButton";
import EventProxy from "../components/EventProxy";
import { Notifications } from "../components/NotificationsComponent";
import SafeSimpleEvent from "../components/SafeSimpleEvent";
import CreatePracticeDialogComponent from "../components/CreatePracticeDialogComponent";
import { PracticeTableContext } from "../components/contexts";
import PracticeTableWithFilter from "../components/table/PracticeTableWithFilter";

const _reducer = function (state, { type, payload }) {
  switch (type) {
    case "SET_DATA": {
      return {
        ...state,
        screenIsReady: true,
      };
    }
    default:
      return state;
  }
};

function _PracticePage(props) {
  const [state, dispatch] = useReducer(_reducer, {
    screenIsReady: true,
    internalEvents: SafeSimpleEvent(),
  });

  const toastr = useContext(Notifications);
  const practiceTableContext = useContext(PracticeTableContext);

  props.didMountEvent.subscribe(() => {
    practiceTableContext.filterEvent.riseEvent();
  }, "PRACTICE_PAGE");

  return state.screenIsReady ? (
    <>
      <PageHeading>
        <PageTitle text="Practices" />
        <ActionGroup>
          <PrimaryButton
            click={() =>
              state.internalEvents.riseEvent({
                type: "create_practice_show_dialog",
              })
            }
            value="Create Practice"
          />
        </ActionGroup>
      </PageHeading>

      <CreatePracticeDialogComponent
        toastr={toastr}
        externalEvents={state.internalEvents}
      />

      <PracticeTableWithFilter
        toastr={toastr}
        context={PracticeTableContext}
        externalEvents={state.internalEvents}
      ></PracticeTableWithFilter>
    </>
  ) : null;
}

class PracticePage extends Component {
  constructor(props) {
    super(props);

    this.didMountEvent = new SafeSimpleEvent();
  }

  componentDidMount() {
    this.didMountEvent.riseEvent();
  }

  render() {
    return <_PracticePage {...this.props} didMountEvent={this.didMountEvent} />;
  }
}

export default PracticePage;
