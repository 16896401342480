import React, { Component } from 'react';

class PrimaryButton extends Component {
  render() {
    return (
      <input type="button" className="primary-button" value={this.props.value} onClick={this.props.click} />
    );
  }
}

export default PrimaryButton;
