import React, { Component, useContext, useReducer } from 'react';
import SafeSimpleEvent from '../components/SafeSimpleEvent';
import SurgeonTableWithFilter from '../components/table/SurgeonTableWithFilter';
import { SurgeonTableContext } from '../components/contexts'
import CreateEntityComponent from '../components/CreateEntityComponent';
import SaveSurgeonForm from '../components/forms/SaveSurgeonForm';
import PageHeading, { PageTitle, ActionGroup } from '../components/PageHeading';
import EventProxy from "../components/EventProxy"
import PageState from '../components/PageState';
import { Notifications } from "../components/NotificationsComponent"
import CreateSurgeonDialog from '../components/CreateSurgeonDialog';
import PrimaryButton from '../components/buttons/PrimaryButton';



const _reducer = function (state, { type, payload }) {
    switch (type) {
        case 'SET_DATA': {
            return {
                ...state,
                screenIsReady: true,
            }
        }
        default:
            return state
    }
}

function _SurgeonPage(props) {
    //initial state
    const [state, dispatch] = useReducer(_reducer, {
        screenIsReady: true,
        setStateEventWrapper: EventProxy(),
        internalEvents: SafeSimpleEvent()
    })

    const surgeonTableContext = useContext(SurgeonTableContext);

    const setCurrentState = (currentState) => {
        state.setStateEventWrapper.riseEvent(currentState)
    }

    const surgeonFormOnSubmitStart = () => {
        setCurrentState({ currentState: "LOADING", location: "site" })
    }

    const toastr = useContext(Notifications)

    const hideLoading = () => {
        setCurrentState({ currentState: "READY", location: "site" })
    }

    props.didMountEvent.subscribe(() => {
        surgeonTableContext.filterEvent.riseEvent()
    }, "SURGEON_PAGE")

    return (state.screenIsReady ? (
        <div>
            <PageHeading >
                <PageTitle text="Surgeons" />
                <ActionGroup>
                    <PrimaryButton value="Create Surgeon" click={() =>{
                        state.internalEvents.riseEvent({
                            type:"create_surgeon_dialog_show"
                        })
                    }}/>
                </ActionGroup>
            </PageHeading>
           
            <CreateSurgeonDialog toastr={toastr} externalEvents={state.internalEvents} />

            <SurgeonTableWithFilter
                context={SurgeonTableContext}
                externalEvents={state.internalEvents}
            />
        </div>) : null
    )
}

class SurgeonsPage extends Component {

    constructor(props) {
        super(props)

        this.didMountEvent = new SafeSimpleEvent();
    };

    componentDidMount() {
        this.didMountEvent.riseEvent();
    };

    render() {
        return (<_SurgeonPage {...this.props} didMountEvent={this.didMountEvent} />)
    }
}


export default SurgeonsPage;
