import React, { Component, useReducer, useContext } from 'react';
import AxiosHelper from '../../utils/AxiosHelper';
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import { QuestionnaireTableContext } from "../contexts"
import SafeSimpleEvent from '../SafeSimpleEvent';
import { EntityStatus, ValidResult } from '../../utils/Utils';
import { MoreAction, MoreActionsMenu, MoreActionsItem, MoreActionsLinkItem } from '../MoreActions';
import UpdateStatusDialogComponent from '../UpdateStatusDialogComponent';
import ListingTagsView from '../ListingTagsView';
import { PageStateHandler } from '../PageState';
import { Notifications } from '../NotificationsComponent';
import { Confirm } from '../ConfirmModule';
import { withRouter } from "react-router";

function _reducer(state, action) {
    switch (action.type) {
        case 'SET_DATA': {
            return {
                ...state,
                screenIsReady: true
            }
        }
        case 'UPDATE_STATUS_QUESTIONNAIRE': {
            return {
                ...state,
                showRemoveEntityModal: true,
                questionnaireToUpdateId: action.payload.questionnaireToUpdateId
            }
        }
        case 'CLOSE_UPDATE_STATUS_QESTIONNAIRE': {
            return {
                ...state,
                questionnaireToUpdateId: '',
                showRemoveEntityModal: false
            }
        }

        default:
            return state
    }
}
function _QuestionnaireTable(props) {
    const [state, dispatch] = useReducer(_reducer, {
        showRemoveEntityModal: false,
        questionnaireToUpdateId: ''
    })

    const toastr = useContext(Notifications)
    const questionnaireTableContext = useContext(QuestionnaireTableContext)
    props.didMountEvent.subscribe(() => {
        dispatch({
            type: "SET_DATA"
        });
    })

    const handleMenuItemClick = (menuItemId, obj, activeIndex) => {
        if (menuItemId == "edit") {
            props.history.push(`/questionnaires/questionnairedetails?questionnaireId=${obj.Id}`);
        }
        else if (menuItemId == "remove") {
            handleRemoveQuestionnaire(obj.Id);
        }
        else if (menuItemId == "duplicate") {
            Confirm.Show(
                `Confirm`,
                `Are you sure you want to duplicate questionnaire "${obj.Name}"`,
                ({ type, payload }) => {
                    if (type === "ok") {
                        duplicate(obj.Id, obj.Name)
                    }
                })
        }
        else if (menuItemId == "delete") {
            Confirm.Show(
                `Confirm`,
                `Are you sure you want to delete questionnaire "${obj.Name}"`,
                ({ type, payload }) => {
                    if (type === "ok") {
                        deleteQuestionnaire(obj.Id, obj.Name)
                    }
                })
        }
    }

    const duplicate = (id, name) => {
        PageStateHandler.SetLoading()

        AxiosHelper
            .post(`/Questionnaire/Duplicate?id=${id}`)
            .then((result) => {
                if (ValidResult(result.data)) {
                    toastr.ShowSuccess(`Successfully duplicated questionnaire "${name}"`)

                    const { Value } = result.data.Data

                    props.history.push(`/questionnaires/savequestionnaire?questionnaireId=${Value}`)
                }
                else {
                    toastr.ShowError(`Failed to duplicate questionnaire "${name}"`)
                }

                PageStateHandler.SetReady()
            })
            .catch((err) => {
                toastr.ShowError("An error occurred, please try again")
                PageStateHandler.SetReady()
            })
    }

    const deleteQuestionnaire = (id, name) => {
        PageStateHandler.SetLoading()

        AxiosHelper
            .post(`/Questionnaire/Delete?id=${id}`)
            .then((result) => {
                if (ValidResult(result.data)) {
                    toastr.ShowSuccess(`Successfully deleted questionnaire "${name}"`)

                    questionnaireTableContext.filterEvent.riseEvent();
                }
                else {
                    toastr.ShowError(`Failed to delete questionnaire "${name}"`)
                }

                PageStateHandler.SetReady()
            })
            .catch((err) => {
                toastr.ShowError("An error occurred, please try again")
                PageStateHandler.SetReady()
            })
    }

    const handleRemoveQuestionnaire = (questionnaireId) => {
        dispatch({
            type: "UPDATE_STATUS_QUESTIONNAIRE",
            payload: {
                questionnaireToUpdateId: questionnaireId,
            }
        })
    }

    const handleCloseRemoveEntityModal = () => {
        dispatch({
            type: "CLOSE_UPDATE_STATUS_QESTIONNAIRE"
        });
    }

    const cancelUpdate = () => {
        dispatch({
            type: "CLOSE_UPDATE_STATUS_QESTIONNAIRE"
        });
    }

    const updateEntity = (id) => {
        AxiosHelper
            .get("Questionnaire/DeleteQuestionnaire?id=" + state.questionnaireToUpdateId)
            .then(response => {
                questionnaireTableContext.filterEvent.riseEvent();
            }).catch(function (err) {
                this.setState({
                });
            });


        dispatch({
            type: "CLOSE_UPDATE_STATUS_QESTIONNAIRE"
        });
    }

    props.didMountEvent.subscribe(() => {
        dispatch({
            type: "SET_DATA"
        });
    })

    const renderQuestionnaireTable = (items) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }
        return (
            <table className="d-table table-with-legend" data-more-actions>
                <thead>
                    <tr className="d-table-row">
                        <th></th>
                        <th>Name</th>
                        <th>tags</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {items.map(questionnaire => {
                        return (
                            <tr className="d-table-row" key={questionnaire.Id} data-issystem={questionnaire.IsTemplate ? "True" : "False"}>
                                <td width="4px"></td>
                                <td onClick={() => props.history.push(`/questionnaires/savequestionnaire?questionnaireId=${questionnaire.Id}&isTemplate=${questionnaire.IsTemplate}`)}>{questionnaire.Name}</td>
                                <td>
                                    <ListingTagsView tags={questionnaire.Tags} />
                                </td>
                                <td>
                                    <MoreActionsMenu callback={handleMenuItemClick}>
                                        <MoreActionsLinkItem to={`/questionnaires/savequestionnaire?questionnaireId=${questionnaire.Id}&isTemplate=${questionnaire.IsTemplate}`} text="Edit" id="edit" key={`${questionnaire.Id}-edit`} obj={questionnaire} />
                                        <MoreActionsItem text="Delete" id="delete" key={`${questionnaire.Id}-delete`} obj={questionnaire} />
                                        <MoreActionsItem text="Duplicate" id="duplicate" key={`${questionnaire.Id}-duplicate`} obj={questionnaire} />
                                    </MoreActionsMenu>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
    return (
        <>
            <div className="template-legend">
                <div>
                    <div></div>
                    <label>Created by user</label>
                </div>
                <div>
                    <div></div>
                    <label>System</label>
                </div>
            </div>

            <PaginationHandler
                id="questionnaires-pagination"
                url="Questionnaire/GetQuestionnairesPage"
                processor={renderQuestionnaireTable}
                activeIndex={EntityStatus.Active}
                context={QuestionnaireTableContext}
            />

            <UpdateStatusDialogComponent
                showRemoveEntityModal={state.showRemoveEntityModal}
                handleClose={handleCloseRemoveEntityModal}
                entity="Questionnaire"
                action={"remove"}
                cancelUpdate={cancelUpdate}
                updateEntity={updateEntity}
            />
        </>
    )
}


class QuestionnaireTable extends Component {
    constructor(props) {
        super(props)

        this.didMountEvent = SafeSimpleEvent()
    }

    componentDidMount(props) {
        this.didMountEvent.riseEvent()
    }

    render() {
        return (
            <_QuestionnaireTable {...this.props} didMountEvent={this.didMountEvent} />
        );
    }
}

export default withRouter(QuestionnaireTable);