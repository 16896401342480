import React, { Component, useContext, useReducer } from 'react'
import uuid from "uuid"

import "../../css/basicPaginationHandler.css"

import AxiosHelper from "../../utils/AxiosHelper"
import SafeSimpleEvent from '../SafeSimpleEvent';
import PaginationPanel from './components/PaginationPanel';

const _reducer = function (state, { type, payload }) {
    switch (type) {
        case 'SET_DATA': {
            const { data, currentState } = payload

            return {
                ...state,
                data: data.Data,
                currentPage: data.CurrentPage,
                totalElements: data.TotalElementsCount,
                totalPageCount: data.TotalPages,
                currentState
            }
        }
        case "SET_CURRENT_STATE": {
            return {
                ...state,
                currentState: payload
            }
        }
        case 'UPDATE_TAKE_COUNT': {
            return {
                ...state,
                takeCount: payload
            }
        }
        default:
            return state
    }
}

function _PaginationHandler(props) {
    const [state, dispatch] = useReducer(_reducer, {
        data: [],
        id: props.id,
        url: props.url,
        activeIndex: props.activeIndex,
        totalPageCount: 0,
        takeCount: props.takeCount ? props.takeCount : 20,
        currentPage: props.currentPage ? props.currentPage : 0,

        currentState: "LOADING"
    })

    const context = useContext(props.context);
    context.filterEvent.subscribe(() => {
        loadPage()
    }, props.id)

    props.mountedEvent.subscribe(() => {
        init()
    }, props.id)

    props.unmountedEvent.subscribe(() => {
        context.filterEvent.unsubscribe(props.id)
    }, props.id)

    const init = () => {
        loadPage()
    }

    const onPageGet = (json) => {
        dispatch({
            type: "SET_DATA",
            payload: json
        })
    }

    const setCurrentState = (state) => {
        dispatch({
            type: "SET_CURRENT_STATE",
            payload: state
        })
    }

    const setData = (data, currentState) => {
        if (!currentState) {
            currentState = state.currentState
        }

        dispatch({
            type: "SET_DATA",
            payload: {
                currentState,
                data
            }
        })
    }

    const loadPage = (page, takeCount) => {
        if (!page) {
            page = 0
        }

        if (!takeCount) {
            takeCount = state.takeCount
        }
        else if (takeCount != state.takeCount) {
            dispatch({
                type: 'UPDATE_TAKE_COUNT',
                payload: takeCount
            })
        }

        setCurrentState("LOADING")
        let filterData = context.formData;
        if (page > state.totalPageCount || page == state.totalPageCount) {
            page = state.totalPageCount-1;
        }
        if (page < 0) {
            page = 0;
        }
        filterData.page = page;
        filterData.takeCount = takeCount;
        filterData.activeIndex = state.activeIndex;

        if (props.hiddenData) {
            Object.keys(props.hiddenData).forEach(key => {
                if (key) {
                    filterData[key] = props.hiddenData[key]
                }
            })
        }

        AxiosHelper.post(state.url, filterData)
            .then(({ data }) => {
                const response = data;
                if (response && response.Success) {
                    setData(response.Data, "READY")
                }
                else {
                    setCurrentState("FAILED")
                }

            }).catch(function (err) {
                setCurrentState("ERROR")
                console.log(err);
            });
    }

    const stateRenderMapping = {}

    stateRenderMapping["READY"] = () => {
        return (
            <>
                <div className="d-pagination-container">
                    {
                        (() => {
                            const data = state.data
                            return props.processor(data, props.id, state.activeIndex)
                        })()
                    }
                </div>
                {
                    (state.data && state.data.length) ?
                        (<div className="d-pagination-panel">
                            <PaginationPanel
                                page={state.currentPage}
                                takeCount={state.takeCount}
                                pageCount={state.totalPageCount}
                                loadPage={loadPage} />
                        </div>) : null
                }
            </>
        )
    }

    stateRenderMapping["ERROR"] = () => {
        return (
            <div className="d-pagination-message">
                <label>An error occurred.</label>
            </div>
        )
    }

    stateRenderMapping["FAILED"] = () => {
        return (
            <div className="d-pagination-message">
                <label>Failed to get the data.</label>
            </div>
        )
    }

    stateRenderMapping["LOADING"] = () => {
        return (
            <div className="d-pagination-message">
                <label>Loading...</label>
            </div>
        )
    }

    return (
        <div className="d-pagination">
            {
                (typeof (stateRenderMapping[state.currentState]) === "function") ?
                    (stateRenderMapping[state.currentState]()) :
                    (
                        <div>
                            <label>Internal error!</label>
                        </div>
                    )
            }
        </div>
    )
}

export class PaginationHandler extends Component {
    constructor(props) {
        super(props)
        this.mountedEvent = SafeSimpleEvent();
        this.unmountedEvent = SafeSimpleEvent();
        this.state = {

        }

    }
    componentDidMount() {
        this.mountedEvent.riseEvent()
    }

    componentWillUnmount() {
        this.unmountedEvent.riseEvent()
    }

    componentWillReceiveProps(props) {
        this.setState({
            hiddenData: props.hiddenData
        })
    }

    render() {
        return (
            <>
                {React.cloneElement(<_PaginationHandler />, {
                    ...this.props,
                    mountedEvent: this.mountedEvent,
                    unmountedEvent: this.unmountedEvent,
                })}
            </>
        )
    }
}

export default PaginationHandler
