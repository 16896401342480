import React, { Component, useContext, useReducer } from 'react';
import HospitalTableWithFilter from '../components/table/HospitalTableWithFilter';
import SafeSimpleEvent from '../components/SafeSimpleEvent';
import PrimaryButton from "../components/buttons/PrimaryButton"
import PageHeading, { PageTitle, ActionGroup } from '../components/PageHeading';
import SaveHospitalDialogComponent from '../components/SaveHospitalDialogComponent';
import { Notifications } from "../components/NotificationsComponent"
import CreateHospitalComponent from '../components/CreateHospitalComponent';
import EventProxy from "../components/EventProxy"

const _reducer = function (state, { type, payload }) {
    switch (type) {
        case 'SET_DATA': {
            return {
                ...state,
                screenIsReady: true,
            }
        }
        default:
            return state
    }
}

function _HospitalPage(props) {
    //initial state
    const [state, dispatch] = useReducer(_reducer, {
        screenIsReady: true,
        isEditHospitalFlyoutVisible: false,
        isCreateHospitalFlyoutVisible: false,

        editHospitalProxy: EventProxy(),
        createHospitalProxy: EventProxy()
    })

    const toastr = useContext(Notifications)

    props.didMountEvent.subscribe(() => {

    })

    return (
        state.screenIsReady ? (
            <>
                <PageHeading >
                    <PageTitle text="Hospitals" />
                    <ActionGroup>
                        <PrimaryButton click={() => state.createHospitalProxy.riseEvent({ type: "show_dialog" })} value="Create Hospital" />
                    </ActionGroup>
                </PageHeading>

                <HospitalTableWithFilter
                    toastr={toastr}
                    createHospitalProxy={state.createHospitalProxy}
                    editHospitalProxy={state.editHospitalProxy} />

                <CreateHospitalComponent
                    toastr={toastr}
                    eventsProxy={state.createHospitalProxy} />

                <SaveHospitalDialogComponent
                    toastr={toastr}
                    eventsProxy={state.editHospitalProxy} />
            </>
        ) : null
    )
}

class HospitalPage extends Component {

    constructor(props) {
        super(props)

        this.didMountEvent = new SafeSimpleEvent();
    };

    componentDidMount() {
        this.didMountEvent.riseEvent();
    };

    render() {
        return (<_HospitalPage {...this.props} didMountEvent={this.didMountEvent} />)
    }
}

export default HospitalPage;