import React, { Component } from 'react';
import TagsTable from './TagsTable';
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import FilterHandler from '../filterAndPagination/FilterHandler';
import SafeSimpleEvent from "../SafeSimpleEvent"

class TagsTableWithFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            internalEvents: SafeSimpleEvent()
        }
    }

    render() {
        return (
            <div>
                <FilterHandler context={this.props.context} externalEvent={this.state.internalEvents}>
                    <TextFilterEntry name="Name" friendlyName="Name" />
                </FilterHandler>
                <TagsTable  {...this.props} />
            </div>
        );
    }
}

export default TagsTableWithFilter;