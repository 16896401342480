import SafeSimpleEvent from "../SafeSimpleEvent";
import React, { Component, useReducer, useContext } from "react";
import { PracticeTableContext } from "../contexts";
import { Notifications } from "../../components/NotificationsComponent";
import AxiosHelper from "../../utils/AxiosHelper";
import { ToArray, EntityStatus } from "../../utils/Utils";
import ListingSurgeonsView from "../ListingSurgeonsView";
import { MoreActionsMenu, MoreActionsItem } from "../MoreActions";
import Tabs from "../tabs/Tabs";
import Tab from "../tabs/components/Tab";
import { PaginationHandler } from "../filterAndPagination/PaginationHandler";
import SavePracticeDialogComponent from "../SavePracticeDialogComponent";
import { PageStateHandler } from "../PageState";
import { ValidResult } from "../../utils/Utils";
import UpdateStatusDialogComponent from "../UpdateStatusDialogComponent";

const PRACTICE_ACTION_TYPES = {
  SET_DATA: "SET_DATA",
  EDIT_PRACTICE: "EDIT_PRACTICE",
  CLOSE_EDIT_PRACTICE: "CLOSE_EDIT_PRACTICE",
  UPDATE_STATUS_PRACTICE: "UPDATE_STATUS_PRACTICE",
  CLOSE_UPDATE_STATUS_PRACTICE: "CLOSE_UPDATE_STATUS_PRACTICE",
};

function _reducer(state, action) {
  switch (action.type) {
    case PRACTICE_ACTION_TYPES.SET_DATA: {
      return {
        ...state,
        practices: action.payload.data,
      };
    }
    case PRACTICE_ACTION_TYPES.EDIT_PRACTICE: {
      return {
        ...state,
        showEditPracticeModal: true,
        practiceId: action.payload.practiceId,
        practice: action.payload.practice,
      };
    }
    case PRACTICE_ACTION_TYPES.CLOSE_EDIT_PRACTICE: {
      return {
        ...state,
        practiceId: "",
        showEditPracticeModal: false,
      };
    }
    case PRACTICE_ACTION_TYPES.UPDATE_STATUS_PRACTICE: {
      return {
        ...state,
        showRemoveEntityModal: true,
        updateDialogAction: action.payload.updateDialogAction,
        practiceToUpdate: action.payload.practiceToUpdate,
      };
    }
    case PRACTICE_ACTION_TYPES.CLOSE_UPDATE_STATUS_PRACTICE: {
      return {
        ...state,
        practiceToUpdate: "",
        showRemoveEntityModal: false,
      };
    }
    default:
      return state;
  }
}

function _PracticeTable(props) {
  const [state, dispatch] = useReducer(_reducer, {
    practiceId: "",
    showEditPracticeModal: false,
    showRemoveEntityModal: false,
    updateDialogAction: "",
    practiceToUpdate: "",
    practice: null,
  });

  const practiceTableContext = useContext(PracticeTableContext);
  const toastr = useContext(Notifications);

  const handleExternalEvents = ({ type, payload }) => {
    if (type === "filter_practice") {
      practiceTableContext.filterEvent.riseEvent();
    }
  };

  if (props.externalEvents) {
    props.externalEvents.subscribe(handleExternalEvents, "PRACTICE_TABLE");
  }

  const handleEditPractice = (practiceId) => {
    PageStateHandler.SetLoading();
    AxiosHelper.get("Practice/GetPractice?id=" + practiceId)
      .then((response) => {
        dispatch({
          type: PRACTICE_ACTION_TYPES.EDIT_PRACTICE,
          payload: {
            practiceId: practiceId,
            showEditPracticeModal: true,
            practice: response.data,
          },
        });
        PageStateHandler.SetReady();
      })
      .catch(function (err) {
        props.toastr.ShowError("An error occurred, please try again");
        PageStateHandler.SetReady();
      });
  };

  const handleCloseEditPracticeModal = () => {
    dispatch({
      type: PRACTICE_ACTION_TYPES.CLOSE_EDIT_PRACTICE,
    });
  };

  const handleSavePracticeModal = () => {
    handleCloseEditPracticeModal();
    practiceTableContext.filterEvent.riseEvent({ type: "filter_practice" });
  };

  props.didMountEvent.subscribe(() => {
    if (props.externalProxy) {
      props.externalProxy.subscribe(handleExternalEvents, "PRACTICE_TABLE");
    }
  });

  const handleMenuItemClick = (menuItemId, obj, activeIndex) => {
    if (menuItemId === "edit") {
      handleEditPractice(obj.Id);
    } else if (menuItemId === "remove") {
      if (activeIndex === EntityStatus.Deleted) {
        handleActicePractice(obj.Id);
      } else {
        handleDeactivePractice(obj.Id);
      }
    }
  };

  const handleActicePractice = (practiceId) => {
    dispatch({
      type: PRACTICE_ACTION_TYPES.UPDATE_STATUS_PRACTICE,
      payload: {
        updateDialogAction: "Activate",
        practiceToUpdate: practiceId,
      },
    });
  };

  const handleDeactivePractice = (practiceId) => {
    dispatch({
      type: PRACTICE_ACTION_TYPES.UPDATE_STATUS_PRACTICE,
      payload: {
        updateDialogAction: "Deactivate",
        practiceToUpdate: practiceId,
      },
    });
  };

  const handleCloseRemoveEntityModal = () => {
    dispatch({
      type: PRACTICE_ACTION_TYPES.CLOSE_UPDATE_STATUS_PRACTICE,
    });
  };

  const updateEntity = () => {
    if (state.updateDialogAction == "Deactivate") {
      AxiosHelper.get(
        "Practice/DeactivatePractice?id=" + state.practiceToUpdate
      )
        .then((response) => {
          if (ValidResult(response.data)) {
            toastr.ShowSuccess("Successfully deactivated the practice");
            practiceTableContext.filterEvent.riseEvent();
          } else {
            handleDeactivateFailResponse();
          }
        })
        .catch(function (err) {
          handleDeactivateFailResponse();
        });
    }
    if (state.updateDialogAction == "Activate") {
      AxiosHelper.get("Practice/ActivatePractice?id=" + state.practiceToUpdate)
        .then((response) => {
          if (ValidResult(response.data)) {
            toastr.ShowSuccess("Successfully activated the practice");
            practiceTableContext.filterEvent.riseEvent();
          } else {
            handleActivateFailResponse();
          }
        })
        .catch(function (err) {
          handleActivateFailResponse();
        });
    }

    dispatch({
      type: PRACTICE_ACTION_TYPES.CLOSE_UPDATE_STATUS_PRACTICE,
    });
  };

  const handleDeactivateFailResponse = () => {
    toastr.ShowError("Failed to deactivate the practice");
    this.setState({});
  };

  const handleActivateFailResponse = () => {
    toastr.ShowError("Failed to activate the practice");
    this.setState({});
  };

  const confirmHandler = ({ type, payload }) => {
    const { subType, obj } = payload;

    if (type === "ok") {
      if (subType === "delete") {
        deletePractice(obj.Id, obj.Name);
      }
    }
  };

  const deletePractice = (id, name) => {
    PageStateHandler.SetLoading();
    AxiosHelper.post(`/Practice/Delete?id=${id}`)
      .then((result) => {
        if (ValidResult(result.data)) {
          props.toastr.ShowSuccess(`Successfully deleted practice "${name}"`);

          practiceTableContext.filterEvent.riseEvent();
        } else {
          props.toastr.ShowError(`Failed to delete practice "${name}"`);
        }

        PageStateHandler.SetReady();
      })
      .catch((err) => {
        console.log(err);
        props.toastr.ShowError("An error occurred, please try again");
        PageStateHandler.SetReady();
      });
  };

  const renderPracticeTable = (items, id, activeIndex) => {
    if (!items || !items.length) {
      return (
        <div className="d-pagination-message">
          <label>No results.</label>
        </div>
      );
    }

    return (
      <table className="d-table" data-more-actions>
        <thead>
          <tr className="d-table-row">
            <th>Practice</th>
            <th>Manager First Name</th>
            <th>Manager Last Name</th>
            <th>Manager Email</th>
            <th>Surgeons</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {ToArray(items).map((practice) => {
            return (
              <tr className="d-table-row" key={practice.Id}>
                <td>{practice.Practice}</td>
                <td>{practice.Manager.FirstName}</td>
                <td>{practice.Manager.LastName}</td>
                <td>
                  <a href={`mailto:${practice.Manager.Email}`}>
                    {practice.Manager.Email}
                  </a>
                </td>
                <td>
                  <ListingSurgeonsView surgeons={practice.Surgeons} />
                </td>
                <td>
                  <MoreActionsMenu
                    callback={handleMenuItemClick}
                    activeIndex={activeIndex}
                  >
                    <MoreActionsItem
                      text="Edit"
                      id="edit"
                      key={`${practice.Id}-edit`}
                      obj={practice}
                    />
                    <MoreActionsItem
                      text={
                        activeIndex == EntityStatus.Deleted
                          ? "Activate"
                          : "Deactivate"
                      }
                      id="remove"
                      key={`${practice.Id}-remove`}
                      obj={practice}
                    />
                  </MoreActionsMenu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <Tabs startTabId="active-patients-tab">
        <Tab friendlyName="Active" id="active-patients-tab">
          <PaginationHandler
            id="active-practices-pagination"
            url="Practice/GetPage"
            activeIndex={EntityStatus.Active}
            processor={renderPracticeTable}
            hiddenData={props.hiddenData}
            context={PracticeTableContext}
          />
        </Tab>
        <Tab friendlyName="Inactive" id="inactive-patients-tab">
          <PaginationHandler
            id="inactive-practices-pagination"
            url="Practice/GetPage"
            activeIndex={EntityStatus.Deleted}
            processor={renderPracticeTable}
            hiddenData={props.hiddenData}
            context={PracticeTableContext}
          />
        </Tab>
      </Tabs>

      <UpdateStatusDialogComponent
        showRemoveEntityModal={state.showRemoveEntityModal}
        handleClose={handleCloseRemoveEntityModal}
        entity="Practice"
        action={state.updateDialogAction}
        cancelUpdate={handleCloseRemoveEntityModal}
        updateEntity={updateEntity}
      />

      <SavePracticeDialogComponent
        showEditPracticeModal={state.showEditPracticeModal}
        handleClose={handleCloseEditPracticeModal}
        onSave={handleSavePracticeModal}
        practice={state.practice}
      />
    </>
  );
}

class PracticeTable extends Component {
  constructor(props) {
    super(props);

    this.didMountEvent = SafeSimpleEvent();
  }

  componentDidMount(props) {
    this.didMountEvent.riseEvent();
  }

  render() {
    return (
      <_PracticeTable {...this.props} didMountEvent={this.didMountEvent} />
    );
  }
}

export default PracticeTable;
