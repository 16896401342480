import React, { Component } from 'react';

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: '',
            imagePreviewUrl: props.url ? props.url : "",
            ref: React.createRef(null)
        };

        if (this.props.showEvent) {
            this.props.showEvent.subscribe(
                this.onShow,
                "IMAGE_UPLOAD"
            )
        }
    }

    onShow = (val) => {
        this.state.ref.current && (
            this.state.ref.current.click()
        )
    }

    handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            },
                () => {
                    this.props.handleImageChange(this.state.imagePreviewUrl, this.state.file);
                });
        }

        reader.readAsDataURL(file)
    }

    componentWillUpdate(props) {
        if (props.url != this.state.imagePreviewUrl) {
            this.setState({
                imagePreviewUrl: props.url
            });
        }
    }

    render() {
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img className="image-preview" src={imagePreviewUrl} />);
        }
        // else {
        //     $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
        // }

        return (
            <div className="picture">
                {$imagePreview}
                <div>
                    <input ref={this.state.ref} type="file" onChange={(e) => this.handleImageChange(e)} style={{ ["display"]: "none" }}
                        accept=".png,.jpg,.jpeg,.bmp" />
                </div>
            </div>
        )
    }
}

export default ImageUpload;