import React, { Component, useReducer, useContext } from 'react';
import Tabs from '../tabs/Tabs';
import Tab from '../tabs/components/Tab';
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import { ToArray, UserStatus } from '../../utils/Utils';
import SafeSimpleEvent from '../SafeSimpleEvent';
import { SurgeonTableContext } from "../contexts"
import SaveSurgeonDialogComponent from '../SaveSurgeonDialogComponent';
import AxiosHelper from '../../utils/AxiosHelper';
import { Popup } from 'semantic-ui-react';
import ListingTagsView from '../ListingTagsView';
import { MoreActionsMenu, MoreActionsItem } from '../MoreActions';
import UpdateStatusDialogComponent from '../UpdateStatusDialogComponent';
import { Notifications } from "../../components/NotificationsComponent"

function _reducer(state, action) {
    switch (action.type) {
        case 'SET_DATA': {
            return {
                ...state,
                surgeons: action.payload.data,
                screenIsReady: true
            }
        }
        case 'EDIT_SURGEON': {
            return {
                ...state,
                showEditSurgeonModal: true,
                surgeonId: action.payload.surgeonId,
                surgeon: action.payload.surgeon
            }
        }
        case 'CLOSE_EDIT_SURGEON': {
            return {
                ...state,
                surgeonId: '',
                showEditSurgeonModal: false
            }
        }
        case 'UPDATE_STATUS_SURGEON': {
            return {
                ...state,
                showRemoveEntityModal: true,
                updateDialogAction: action.payload.updateDialogAction,
                surgeonToUpdate: action.payload.surgeonToUpdate,
            }
        }
        case 'CLOSE_UPDATE_STATUS_SURGEON': {
            return {
                ...state,
                surgeonToUpdate: '',
                showRemoveEntityModal: false
            }
        }
        default:
            return state
    }
}
function _SurgeonTable(props) {
    const [state, dispatch] = useReducer(_reducer, {
        surgeonId: '',
        showEditSurgeonModal: false,
        showRemoveEntityModal: false,
        updateDialogAction: '',
        surgeonToUpdate: '',
        surgeon: null
    })

    const surgeonTableContext = useContext(SurgeonTableContext)
    const toastr = useContext(Notifications)

    const handleExternalEvents = ({ type, payload }) => {
        if (type === "filter_surgeons") {
            surgeonTableContext.filterEvent.riseEvent()
        }
    }

    if(props.externalEvents){
        props.externalEvents.subscribe(handleExternalEvents, "SURGEON_TABLE")
    }

    const handleEditSurgeon = (surgeonId) => {

        AxiosHelper.post("Surgeon/GetSurgeon?id=" + surgeonId).then(response => {
            let tags = response.data.Tags.map(tag => {
                return tag.Id;
            });
            let hospitals = response.data.Hospitals.map(hospital => {
                return hospital.Id;
            });
            dispatch({
                type: "EDIT_SURGEON",
                payload: {
                    surgeonId: surgeonId,
                    showEditSurgeonModal: true,
                    surgeon: {
                        id: response.data.Id,
                        firstName: response.data.FirstName,
                        lastName: response.data.LastName,
                        email: response.data.Email,
                        address: response.data.Address,
                        website: response.data.Website,
                        phone: response.data.Phone,
                        hospitals: hospitals,
                        tags: tags
                    }
                },
            });
        }).catch(function (err) {

        });
    }

    const handleCloseEditSurgeonModal = () => {
        dispatch({
            type: "CLOSE_EDIT_SURGEON"
        });
        surgeonTableContext.filterEvent.riseEvent();
    };

    const handleCloseRemoveEntityModal = () => {
        dispatch({
            type: "CLOSE_UPDATE_STATUS_SURGEON"
        });
    }

    const cancelUpdate = () => {
        dispatch({
            type: "CLOSE_UPDATE_STATUS_SURGEON"
        });
    }

    const updateEntity = () => {
        if (state.updateDialogAction == 'Deactivate') {
            AxiosHelper.get("Surgeon/DeleteSurgeon?id=" + state.surgeonToUpdate).then(response => {
                toastr.ShowSuccess("Successfully deactivated the surgeon")
                surgeonTableContext.filterEvent.riseEvent();
            }).catch(function (err) {
                toastr.ShowError("Failed to deactivate the surgeon")
                this.setState({
                });
            });
        }
        if (state.updateDialogAction == 'Activate') {
            AxiosHelper.get("Surgeon/ActivateSurgeon?id=" + state.surgeonToUpdate).then(response => {
                toastr.ShowSuccess("Successfully activated the surgeon")
                surgeonTableContext.filterEvent.riseEvent();
            }).catch(function (err) {
                toastr.ShowError("Failed to activate the surgeon")
                this.setState({
                });
            });
        }

        dispatch({
            type: "CLOSE_UPDATE_STATUS_SURGEON"
        });
    }

    const handleRemoveSurgeon = (surgeonId) => {
        dispatch({
            type: "UPDATE_STATUS_SURGEON",
            payload: {
                updateDialogAction: "Deactivate",
                surgeonToUpdate: surgeonId,
            }
        })
    }

    const handleActivateSurgeon = (surgeonId) => {
        dispatch({
            type: "UPDATE_STATUS_SURGEON",
            payload: {
                updateDialogAction: "Activate",
                surgeonToUpdate: surgeonId,
            }
        })
    }

    props.didMountEvent.subscribe(() => {
        if (props.externalProxy) {
            props.externalProxy.subscribe(handleExternalEvents, "SURGEON_TABLE")
        }

        if (props.saveHospitalProxy) {
            props.saveHospitalProxy.subscribe(handleExternalEvents, "SURGEON_TABLE")
        }
    })

    const handleMenuItemClick = (menuItemId, obj, activeIndex) => {
        if (menuItemId == "edit") {
            handleEditSurgeon(obj.Id)
        }
        else if (menuItemId === "remove") {
            if (activeIndex === UserStatus.Deleted) {
                handleActivateSurgeon(obj.Id)
            }
            else {
                handleRemoveSurgeon(obj.Id)
            }
        }
    }

    const renderSurgeonTable = (items, id, activeIndex) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }
        return (
            <table className="d-table" data-more-actions>
                <thead>
                    <tr className="d-table-row">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Website</th>
                        <th>Tags</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {
                        ToArray(items).map(surgeon => {
                            return (
                                <tr className="d-table-row" key={surgeon.Id}>
                                    <td onClick={() => { handleEditSurgeon(surgeon.Id) }}>{surgeon.Name}</td>
                                    <td><a href={`mailto:${surgeon.Email}`}>{surgeon.Email}</a></td>
                                    <td><a href={`tel:${surgeon.Phone}`}>{surgeon.Phone}</a></td>
                                    <td>{surgeon.Address}</td>
                                    <td ><a href={surgeon.Website} target="_blank">{surgeon.Website}</a></td>
                                    <td>
                                        <ListingTagsView tags={surgeon.Tags} />
                                    </td>
                                    <td>
                                        <MoreActionsMenu callback={handleMenuItemClick} activeIndex={activeIndex} >
                                            <MoreActionsItem text="Edit" id="edit" key={`${surgeon.Id}-edit`} obj={surgeon} />
                                            <MoreActionsItem text={activeIndex == UserStatus.Deleted ? "Activate" : "Deactivate"} id="remove" key={`${surgeon.Id}-remove`} obj={surgeon} />
                                        </MoreActionsMenu>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        );
    }

    return (
        <>
            <Tabs startTabId="active-patients-tab">
                <Tab friendlyName="Active" id="active-patients-tab" >
                    <PaginationHandler
                        id="active-surgeons-pagination"
                        url="Surgeon/GetSurgeonsPage"
                        activeIndex={UserStatus.Approved}
                        processor={renderSurgeonTable}
                        hiddenData={props.hiddenData}
                        context={SurgeonTableContext} />

                </Tab>
                <Tab friendlyName="Inactive" id="inactive-patients-tab" >
                    <PaginationHandler
                        id="inactive-surgeons-pagination"
                        url="Surgeon/GetSurgeonsPage"
                        activeIndex={UserStatus.Deleted}
                        processor={renderSurgeonTable}
                        hiddenData={props.hiddenData}
                        context={SurgeonTableContext} />
                </Tab>
            </Tabs>

            <UpdateStatusDialogComponent
                showRemoveEntityModal={state.showRemoveEntityModal}
                handleClose={handleCloseRemoveEntityModal}
                entity="Surgeon"
                action={state.updateDialogAction}
                cancelUpdate={cancelUpdate}
                updateEntity={updateEntity}
            />

            <SaveSurgeonDialogComponent
                showEditSurgeonModal={state.showEditSurgeonModal}
                handleClose={handleCloseEditSurgeonModal}
                hospitalId={props.hospitalId}
                surgeon={state.surgeon}
            />
        </>
    )
}

class SurgeonTable extends Component {
    constructor(props) {
        super(props)

        this.didMountEvent = SafeSimpleEvent()
    }

    componentDidMount(props) {
        this.didMountEvent.riseEvent()
    }

    render() {
        return (
            <_SurgeonTable {...this.props} didMountEvent={this.didMountEvent} />
        );
    }
}

export default SurgeonTable;